import React from 'react';
import { Link } from 'react-router-dom';

import type { FC, PropsWithChildren } from 'react';

interface Props {
  href?: string;
  action?: () => void;
}

const UnifiedMenuItemWrapper: FC<PropsWithChildren<Props>> = ({ children, href, action }) => {
  if (href) {
    return (
      <Link to={href} className="dropdown-link">
        {children}
      </Link>
    );
  }

  if (action) {
    return (
      <a href="#" onClick={() => action()} className="dropdown-link" style={{ cursor: 'pointer' }}>
        {children}
      </a>
    );
  }

  return <div>{children}</div>;
};

export default UnifiedMenuItemWrapper;
