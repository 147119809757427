import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { Ref, Button as SemanticButton } from 'semantic-ui-react';

import type { FC, ReactNode } from 'react';
import type { ButtonProps as SemanticButtonProps } from 'semantic-ui-react';

import './Button.css';

import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ButtonType = 'primary' | 'normal' | 'error' | 'positive' | 'transparent';
type ButtonSize = 'xs' | 'sm' | 'md' | 'lg';

export interface ButtonProps
  extends Omit<SemanticButtonProps, 'size' | 'type' | 'fullWidth' | 'borderless' | 'icon' | 'center'> {
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  size?: ButtonSize;
  type?: ButtonType;
  fullWidth?: boolean;
  borderless?: boolean;
  icon?: React.ReactNode;
  center?: boolean;
  htmlType?: 'button' | 'submit' | 'reset';

  onWidthChange?: (width: number) => void;
}

const Button: FC<ButtonProps> = ({
  id,
  disabled,
  content,
  iconLeft,
  iconRight,
  size = 'md',
  type = 'normal',
  fullWidth = false,
  borderless = false,
  icon,
  center,
  style,
  htmlType,
  children,
  className,
  isLoading,

  onClick,
  onWidthChange,
  ...restProps
}: ButtonProps) => {
  const buttonClass = classNames('eeedoButton', `size__${size}`, `type__${type}`, className, {
    iconOnly: !!icon,
    center: !!center,
    borderless: !!borderless,
    fullWidth: !!fullWidth
  });
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const updateWidth = () => {
      if (ref.current && onWidthChange) {
        const width = ref.current.offsetWidth;
        onWidthChange(width);
      }
    };
    updateWidth();

    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [onWidthChange, content, iconLeft, iconRight]);

  return (
    <Ref innerRef={ref}>
      <SemanticButton
        id={id}
        type={htmlType}
        style={style}
        className={buttonClass}
        disabled={disabled}
        onClick={onClick}
        {...restProps}
      >
        {isLoading ? (
          <span className="icon-wrapper">
            <FontAwesomeIcon icon={faSpinner} spin size="xl" />
          </span>
        ) : (
          <>
            {iconLeft && <span className="icon-wrapper">{iconLeft}</span>}
            {(content || children) && <span className="content-wrapper">{content ?? children}</span>}
            {icon && <span className="icon-wrapper">{icon}</span>}
            {iconRight && <span className="icon-wrapper">{iconRight}</span>}
          </>
        )}
      </SemanticButton>
    </Ref>
  );
};

export default React.memo(Button);
