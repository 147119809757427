import { useRouteMatch } from 'react-router-dom';

const useIsActiveContentView = () => {
  return useRouteMatch({
    path: ['/case/:id', '/infopage/:id'],
    exact: true,
    strict: false
  });
};

export default useIsActiveContentView;
