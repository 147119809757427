import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import type { TicketType } from '@eeedo/types';

import { getWidgetIcon } from 'src/Components/Case/Widget/widgetsConfig';
import { useWidgetView } from 'src/Components/Case/Widget/WidgetViewContext';
import Button from 'src/Components/generic/Button/Button';
import Popup from 'src/Components/generic/Popup/Popup';
import { getWidgetOrder } from 'src/Components/Utilities/widgets';

import type { State } from 'src/types/initialState';
import type { Ticket } from 'src/types/Ticket';

export const DesktopLayoutWidgetMenu = () => {
  const { selectedWidget, setSelectedWidget, isSingleWidgetView, isRightSidebarOpen, toggleRightSidebar } =
    useWidgetView();
  const wrapperClass = classNames('layout-desktop-widget-side-menu', {
    singleWidget: !!isSingleWidgetView
  });
  const currentTask = useSelector<State>((state) =>
    state.detailedTickets.find((ticket) => ticket.id === state.activeTicketTab)
  ) as Ticket;
  const ticketTypes = useSelector<State>((state) => state.ticketTypes) as TicketType[];

  const widgets = getWidgetOrder(currentTask, ticketTypes);

  const handleSelectWidget = useMemo(
    () => (widget: string) => {
      if (!isRightSidebarOpen) {
        toggleRightSidebar();
      }
      setSelectedWidget(widget);
    },
    [isRightSidebarOpen, toggleRightSidebar, setSelectedWidget]
  );

  return (
    <div className={wrapperClass}>
      <Button
        className="icon-wrapper"
        type="transparent"
        icon={<FontAwesomeIcon icon={isRightSidebarOpen ? faArrowRight : faArrowLeft} />}
        borderless
        onClick={() => toggleRightSidebar()}
      />
      {widgets.map((widget) => {
        const itemClass = classNames('icon-wrapper', {
          active: isRightSidebarOpen && (widget.displayName === selectedWidget || widget.name === selectedWidget)
        });
        return (
          <Popup
            on={'hover'}
            positionFixed
            delay={1000}
            trigger={
              <div className={itemClass} onClick={() => handleSelectWidget(widget.displayName || widget.name)}>
                <span className="icon">
                  <span>{getWidgetIcon(widget.name)}</span>
                </span>
              </div>
            }
            content={widget.displayName || widget.name}
            position="left center"
          />
        );
      })}
    </div>
  );
};
