import { DefaultTicketsOrdering } from '@eeedo/types';
import { faMagnifyingGlass, faSlidersSimple } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Menu, MenuItem } from 'semantic-ui-react';

import type { FC } from 'react';

import { toggleToolsActiveTab } from 'src/reducers/ticketListActiveTabsReducer';
import { useAppSelector } from 'src/store';

import type { TicketListActiveTabs } from 'src/reducers/ticketListActiveTabsReducer';

const [defaultDirection, defaultSorting] = DefaultTicketsOrdering;

const Tabs: FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const activeTab = useAppSelector((state) => state.ticketListActiveTabs.tools);
  const { filters, sorting, direction } =
    useAppSelector((state) => state.ticketListTabs[state.ticketListActiveTabs.main]) ?? {};

  const onClickTab = useCallback(
    (tab: TicketListActiveTabs['tools']) => () => dispatch(toggleToolsActiveTab(tab)),
    [dispatch, toggleToolsActiveTab]
  );

  const isFilterOn =
    !Object.keys(filters ?? {}).every((k) => !filters?.[k]) ||
    (sorting && sorting !== defaultSorting) ||
    (direction && direction !== defaultDirection);

  return (
    <Menu pointing secondary className="ticket-list-tabs__tabs">
      <MenuItem
        active={activeTab === 'filter'}
        content={t('SEARCH_FILTERTERM')}
        onClick={onClickTab('filter')}
        icon={<FontAwesomeIcon icon={faSlidersSimple} />}
        className={isFilterOn ? 'is-filter-on' : ''}
      />
      <MenuItem
        active={activeTab === 'search'}
        icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
        content={t('SEARCH')}
        onClick={onClickTab('search')}
      />
    </Menu>
  );
};

export default React.memo(Tabs);
