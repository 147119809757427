import { Direction } from '@eeedo/types';
import { camelCase } from 'lodash-es';
import { groupBy, intersection, prop, uniq } from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Accordion } from 'semantic-ui-react';

import type { FC } from 'react';
import type { ConnectedProps } from 'react-redux';

import RelationsErrorMessage from './RelationsErrorMessage';
import TicketList from './TicketRelationsList';
import { fetchLinkedTickets, removeTicketLinkingFromTicket } from 'src/actions/ticketsActions';
import TicketRelationsForm from 'src/Components/Case/Widget/TicketRelationsWidget/TicketRelationsForm';
import Tabs from 'src/Components/generic/Tabs/Tabs';
import SocketInstance from 'src/realTimeNotifications';
import { StaticTabs } from 'src/types/TicketList';
import { convertCaseNaming } from 'src/Utilities/helper';
import { ticketOrdering } from 'src/Utilities/ticketList';

import type { ContentTypes } from 'src/types/ContentTypes';
import type { InfoPageListTab } from 'src/types/InfoPageList';
import type { State } from 'src/types/initialState';
import type { LinkedTicket, LinkedTickets, RelationOptions } from 'src/types/LinkedTickets';
import type { MenuTab } from 'src/types/MenuTab';
import type { InfopageStatusTypes, StatusTypes, Ticket, TicketListTicket } from 'src/types/Ticket';
import type { TicketListTab } from 'src/types/TicketList';
import type { UserCustomTicketOrdering } from 'src/types/User';

type TicketListOption = [number, string, (TicketListTicket | LinkedTicket)[], string];

const groupTicketsByStatus = groupBy<{ status: StatusTypes | InfopageStatusTypes }>(prop('status'));
const getTicketsWithSameOrigin = (tickets: TicketListTicket[], task: Ticket) => {
  const taskEntityIds = task.entities.map(({ _id }) => _id);
  return tickets.filter(
    (ticket) =>
      ticket.id !== task.id && ticket.entityIds?.length && intersection(ticket.entityIds, taskEntityIds).length
  );
};
const mapTicketListOption = ([index, title, groupedTickets, ticketGroupName, userCustomTicketOrdering]: [
  ...TicketListOption,
  UserCustomTicketOrdering
]): TicketListOption => [
  index,
  title,
  ticketOrdering<TicketListTicket | LinkedTicket>(
    (groupedTickets || []) as (TicketListTicket | LinkedTicket)[],
    null,
    Direction.ASC,
    userCustomTicketOrdering
  ),
  ticketGroupName
];

interface Props extends TicketRelatinsReduxProps {
  type: ContentTypes;
  task: Ticket;
  createText: string;
  attachPlaceholderText: string;
  parentsText: string;
  childrenText: string;
  sameOriginText: string;
  relationOptions: RelationOptions;
}

const TicketRelations: FC<Props> = ({
  task,
  type,
  tags,
  tickets,
  channels,
  userData,
  usersList,
  priorities,
  createText,
  parentsText,
  ticketTypes,
  childrenText,
  personalData,
  linkedTickets,
  sameOriginText,
  relationOptions,
  activeTabHasError,
  attachPlaceholderText,
  fetchLinkedTickets,
  removeTicketLinkingFromTicket,
  subscribeToRelatedTicketsRooms
}) => {
  const { t } = useTranslation();
  const [showError, setShowError] = useState(true);
  const [activeAccordion, setActiveAccordion] = useState<number | undefined>();
  const [activeTab, setActiveTab] = useState('sameOrigin');

  useEffect(() => {
    if (task && linkedTickets.activeTicketId !== task.id) {
      fetchLinkedTickets(task.id);
    }
  }, [task.id, linkedTickets.activeTicketId]);

  useEffect(() => {
    subscribeToRelatedTicketsRooms(linkedTickets.tickets);
  }, [linkedTickets.tickets, task.id, activeAccordion, activeTab, showError]);

  const toggleActive = useCallback(
    (index: number) => {
      setActiveAccordion(activeAccordion === index ? undefined : index);
    },
    [activeAccordion]
  );

  const tabsTickets = useMemo(() => {
    const { parentTickets = [], childTickets = [] } = linkedTickets.tickets;
    const sameOriginTickets = getTicketsWithSameOrigin(tickets, task);

    return {
      parent: parentTickets,
      child: childTickets,
      sameOrigin: sameOriginTickets
    };
  }, [tickets, linkedTickets.tickets]);

  const ticketListOptions = useMemo(() => {
    const groupedActiveTickets = groupTicketsByStatus(tabsTickets[activeTab]);
    const options = (
      type === 'infopage'
        ? [
            [
              0,
              t('ticket_list.status.draft'),
              groupedActiveTickets.draft,
              'draft',
              personalData.userCustomTicketOrdering
            ],
            [
              1,
              t('ticket_list.status.in_review'),
              groupedActiveTickets.inReview,
              'inReview',
              personalData.userCustomTicketOrdering
            ],
            [
              2,
              t('ticket_list.status.waiting_to_be_published'),
              groupedActiveTickets.waitingToBePublished,
              'waitingToBePublished',
              personalData.userCustomTicketOrdering
            ],
            [
              3,
              t('ticket_list.status.published'),
              groupedActiveTickets.published,
              'published',
              personalData.userCustomTicketOrdering
            ],
            [
              4,
              t('ticket_list.status.waste'),
              groupedActiveTickets.waste,
              'waste',
              personalData.userCustomTicketOrdering
            ]
          ]
        : [
            [0, t('CASE_STATUS_TODO_PLURAL'), groupedActiveTickets.todo, 'todo', personalData.userCustomTicketOrdering],
            [
              1,
              t('CASE_STATUS_DOING_PLURAL'),
              groupedActiveTickets.doing,
              'doing',
              personalData.userCustomTicketOrdering
            ],
            [2, t('CASE_STATUS_DONE_PLURAL'), groupedActiveTickets.done, 'done', personalData.userCustomTicketOrdering]
          ]
    ) as Parameters<typeof mapTicketListOption>[0][];

    return options.map(mapTicketListOption);
  }, [linkedTickets.tickets, tickets, task, personalData.userCustomTicketOrdering, type, activeTab, tabsTickets]);

  if (activeTabHasError && showError) {
    return <RelationsErrorMessage setShowError={(show) => setShowError(show)} />;
  }

  return (
    <>
      <TicketRelationsForm
        task={task}
        type={type}
        userData={userData}
        createText={createText}
        ticketTypes={ticketTypes}
        relationOptions={relationOptions}
        attachPlaceholderText={attachPlaceholderText}
      />
      <>
        {userData.permissions.includes('showTicketlist') && (
          <>
            <Tabs
              activeItem={activeTab}
              setActiveItem={setActiveTab}
              items={[
                { key: 'sameOrigin', title: `${sameOriginText} (${tabsTickets.sameOrigin.length})` },
                { key: 'parent', title: `${parentsText} (${tabsTickets.parent.length})` },
                { key: 'child', title: `${childrenText} (${tabsTickets.child.length})` }
              ]}
            />

            <Accordion
              className="ticketlist"
              style={{
                padding: '0px !important',
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
              }}
            >
              {ticketListOptions.map(([index, title, tickets, ticketGroupName]) => {
                const sortedTickets = ticketOrdering(
                  tickets || [],
                  null,
                  Direction.ASC,
                  personalData.userCustomTicketOrdering
                );

                return (
                  <TicketList
                    ticketListId={camelCase(`relationsTicketlist ${ticketGroupName}`)}
                    ticketTypes={ticketTypes}
                    active={activeAccordion === index}
                    index={index}
                    key={index}
                    onClick={toggleActive}
                    tickets={sortedTickets}
                    title={title}
                    tags={tags}
                    channels={channels}
                    priorities={priorities}
                    removeTicketLinkingFromTicket={removeTicketLinkingFromTicket}
                    usersList={usersList}
                    userData={userData}
                  />
                );
              })}
            </Accordion>
          </>
        )}
      </>
    </>
  );
};

function mapStateToProps(state: State, ownProps: { type: ContentTypes }) {
  const tabs: MenuTab[] =
    ownProps.type === 'task' ? Object.values(state.ticketListTabs) : [...state.infoPageListTabs.values()];
  const activeTabHasError = tabs.find((ticketListTab) => ticketListTab.activeTab)?.hasError;
  const ticketsListTab = tabs.find((ticketListTab) => ticketListTab.id === StaticTabs.MAIN_VIEW);

  const tickets: TicketListTicket[] =
    (ownProps.type === 'task'
      ? (ticketsListTab as TicketListTab).tickets
      : (ticketsListTab as InfoPageListTab).infoPages) ?? [];

  return {
    tickets,
    tags: state.tags,
    activeTabHasError,
    channels: state.channels,
    userData: state.userData,
    personalData: state.userData,
    ticketTypes: state.ticketTypes,
    priorities: state.ticketPriorities,
    linkedTickets: state.linkedTickets,
    usersList: state.usersList.usersList
  };
}

function mapDispatchToProps(dispatch: any, ownProps: { type: ContentTypes }) {
  return {
    fetchLinkedTickets: (id: string) => {
      dispatch(fetchLinkedTickets({ id }));
    },
    // TODO: move utils
    subscribeToRelatedTicketsRooms: (ticketsIds: LinkedTickets['tickets']) => {
      if (ticketsIds) {
        const relatedTicketArray = uniq([
          ...(ticketsIds.childTickets || []).map((t) => t.id),
          ...(ticketsIds.parentTickets || []).map((t) => t.id)
        ]);
        relatedTicketArray.forEach((ticketId) => {
          SocketInstance.joinRoom(convertCaseNaming(ticketId, 'string', ownProps.type) as string);
        });
      }
    },
    removeTicketLinkingFromTicket: async (...args: Parameters<typeof removeTicketLinkingFromTicket>) => {
      dispatch(removeTicketLinkingFromTicket(...args));
    }
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type TicketRelatinsReduxProps = ConnectedProps<typeof connector>;

export default connector(TicketRelations);
