import React from 'react';
import { Menu } from 'semantic-ui-react';

import type { FC } from 'react';
import type { SemanticWIDTHS } from 'semantic-ui-react';

import './Tabs.css';

interface TabItem {
  key: string;
  title: string;
}

interface TabsProps extends Omit<React.ComponentProps<typeof Menu>, 'items'> {
  activeItem: string;
  items: TabItem[];
  setActiveItem: (key: string) => void;
}

const Tabs: FC<TabsProps> = ({ activeItem, items, setActiveItem, ...res }) => (
  <Menu pointing fluid widths={items.length.toString() as SemanticWIDTHS} secondary className="eeedoTabs" {...res}>
    {items.map((item) => (
      <Menu.Item
        className="eeedoTabsItem"
        key={item.key}
        active={activeItem === item.key}
        content={item.title}
        onClick={() => setActiveItem(item.key)}
      />
    ))}
  </Menu>
);

export default React.memo(Tabs);
