import iziToast from 'izitoast';
import { cloneDeep } from 'lodash-es';
import React from 'react';
import { Translation } from 'react-i18next';
import { Button, Form, Icon, Message } from 'semantic-ui-react';

import AccordionHeader from '../AccordionHeader';
import Info from '../Info/Info';

interface MetadataEditorProps {
  disabled: boolean;
  metadata: object | null | undefined | string;
  onSave: (metadata: object) => void;
}

interface MetadataEditorState {
  metadata: object;
  noMetaData: boolean;
}

export class MetadataEditor extends React.Component<MetadataEditorProps, MetadataEditorState> {
  constructor(props: MetadataEditorProps) {
    super(props);

    this.state = {
      ...this.getInitialState(props)
    };
  }

  getInitialState = (props: MetadataEditorProps) => {
    const [parsedMetaData, noMetaData] = this.parseMetaData(props.metadata);
    return {
      metadata: parsedMetaData,
      noMetaData
    };
  };

  componentWillReceiveProps(nextProps: MetadataEditorProps) {
    if (nextProps !== this.props) {
      this.setState({
        ...this.getInitialState(nextProps)
      });
    }
  }

  parseMetaData = (metadata: object | null | undefined | string) => {
    if (typeof metadata === 'string') {
      try {
        const parsed = JSON.parse(metadata);
        return [parsed, false];
      } catch (error) {
        console.error('Failed to parse metadata');
        iziToast.error({ title: 'ERROR_FIELD_COULD_NOT_PARSE', message: 'CONTACT_ADMIN' });
        return [{}, true];
      }
    } else {
      return [{}, true];
    }
  };

  getFields = (metadata: object) => {
    return Object.keys(metadata).map((key: string, index: number) => {
      return {
        key: `${key}-${index}`,
        name: key,
        value: key
      };
    });
  };

  onChange = (oldValue: any, newValue: any, object: object, partial: boolean, fieldName: string) => {
    const metadata = cloneDeep(this.state.metadata);
    metadata[fieldName] = newValue;
    this.setState({ metadata });
  };

  onSave = () => {
    this.props.onSave(this.state.metadata);
  };

  resetMetaData = () => {
    const [parsedMetadata, error] = this.parseMetaData(this.props.metadata);
    if (error) {
      this.setState({ noMetaData: true });
    } else {
      this.setState({ metadata: parsedMetadata });
    }
  };

  render() {
    const hasMetaData = this.props.metadata !== null && this.props.metadata && this.props.metadata !== undefined;

    return (
      <Translation ns="translations">
        {(tr) => (
          <AccordionHeader as="h4" active={true} title={tr('METADATA_TITLE')} icon="list" isWidget={true}>
            <div style={{ padding: '0.5rem' }}>
              {hasMetaData && (
                <React.Fragment>
                  <Info
                    fields={this.getFields(this.state.metadata)}
                    values={this.state.metadata}
                    onSave={this.onChange}
                  />
                  <Form>
                    <Form.Group widths="equal">
                      <Button
                        icon={true}
                        labelPosition="left"
                        negative={true}
                        fluid={true}
                        onClick={this.resetMetaData}
                      >
                        <Icon name="eraser" />
                        {tr('GENERAL_CLEAR')}
                      </Button>
                      <Button icon={true} labelPosition="left" primary={true} fluid={true} onClick={this.onSave}>
                        <Icon name="save" />
                        {tr('GENERAL_SAVE')}
                      </Button>
                    </Form.Group>
                  </Form>
                </React.Fragment>
              )}
              {!hasMetaData && (
                <Message info={true} icon={true}>
                  <Icon name="info circle" />
                  <Message.Header>
                    {tr('METADATA_TITLE')}
                    <p>{tr('NO_METADATA_DESCRIPTION')}</p>
                  </Message.Header>
                </Message>
              )}
            </div>
          </AccordionHeader>
        )}
      </Translation>
    );
  }
}
