import React from 'react';

import type { FC } from 'react';

import Label from '../generic/Label/Label';
import * as styles from './HideMessagesToggle.style';

interface HideMessagesToggleProps {
  text: string;
  disableCollapsing: boolean;

  onClick(): void;
}

const HideMessagesToggle: FC<HideMessagesToggleProps> = ({ text, disableCollapsing, onClick }) => {
  if (disableCollapsing) return null;

  return (
    <p onClick={onClick} style={styles.wrapper}>
      <Label style={styles.label}>{text}</Label>
    </p>
  );
};

export default React.memo(HideMessagesToggle);
