import React from 'react';

export default () => {
  return (
    <svg width="32" height="40" viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8817 2C12.0576 2 9.84451 2 8.5914 2C4.81258 2 1.60059 5.34884 1.60059 9.04876C1.60059 10.6692 1.60059 23.8935 1.60059 30.3841C1.60059 35.1103 4.89356 38 9.21221 38H22.9509C26.6758 38 30.4006 35.4074 30.4006 30.3841C30.4006 27.6448 30.4006 21.8183 30.4006 18.042M13.8817 2C14.8997 2 15.7966 2 16.338 2C19.1451 2 22.8799 3.26381 25.4341 5.53788C28.6191 8.37359 30.4006 12.2896 30.4006 16.2056C30.4006 16.728 30.4006 17.3506 30.4006 18.042M13.8817 2C17.9035 2 19.604 5.13278 19.604 7.32033C19.604 7.70743 19.604 8.62206 19.604 9.1838C19.604 11.3983 21.4124 12.7757 23.1938 12.7757C23.4638 12.7757 24.2303 12.7757 25.1372 12.7757C27.4045 12.7757 30.4006 14.8822 30.4006 18.042"
        stroke="#CFCFCF"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
