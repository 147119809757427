import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import type { CSSProperties, FC, ReactNode } from 'react';

import * as styles from './Collapsible.style';

interface CollapsibleProps {
  heading?: ((isOpen: boolean) => ReactNode) | ReactNode;
  children: ReactNode;
  title?: ReactNode;
  style?: CSSProperties;
  openByDefault?: boolean;
  isNoWrapper?: boolean;
}

const Collapsible: FC<CollapsibleProps> = ({ heading, title, style, isNoWrapper, openByDefault = false, children }) => {
  const [isOpen, setIsOpen] = useState(openByDefault);
  const [overflow, setOverflow] = useState<CSSProperties['overflow']>('hidden');
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setOverflow('visible');
      }, 300);
    } else {
      setOverflow('hidden');
    }
  }, [isOpen]);

  const toggleCollapse = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const getContentHeight = () => (isOpen ? `${contentRef.current?.scrollHeight ?? 0}px` : '0px');

  return (
    <div style={{ ...styles.wrapper(isNoWrapper), ...style }}>
      <div onClick={toggleCollapse} style={styles.headerStyle}>
        <CollapseHead title={title} heading={heading} isOpen={isOpen} />
      </div>
      <div ref={contentRef} style={{ ...styles.contentStyle, height: getContentHeight(), overflow }}>
        {children}
      </div>
    </div>
  );
};

const CollapseHead = React.memo(
  ({ title, heading, isOpen }: Pick<CollapsibleProps, 'title' | 'heading'> & { isOpen: boolean }) => {
    if (heading) {
      return typeof heading === 'function' ? heading(isOpen) : heading;
    }
    return (
      <>
        <div style={styles.title}>{title}</div>
        <FontAwesomeIcon icon={faChevronRight} rotation={isOpen ? 90 : undefined} />
      </>
    );
  }
);

export default React.memo(Collapsible);
