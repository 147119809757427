import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';

import type { CSSProperties } from 'react';
import type { StrictDropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';

import { HelpPopup } from '../Utilities/FormUtilities';

interface NoDefaultDropdownFilterProps<T extends StrictDropdownProps['value']> {
  id?: string;
  options: StrictDropdownProps['options'];
  value: T | undefined;
  placeholder: StrictDropdownProps['placeholder'];
  sidePopup?: string;
  style?: CSSProperties;
  disabled?: boolean;

  onChange: (value: T) => void;
}

interface DropdownFilterProps<T extends StrictDropdownProps['value']> extends NoDefaultDropdownFilterProps<T> {
  defaultValue: T;
  multiple: boolean;
}

const defaultStyle: CSSProperties = { display: 'flex', alignItems: 'center' };

const DropdownFilter = <T extends StrictDropdownProps['value']>(props: DropdownFilterProps<T>) => {
  const { t } = useTranslation();

  return (
    <span style={props.style ? { ...defaultStyle, ...props.style } : defaultStyle}>
      <Dropdown
        id={props.id}
        options={props.options}
        size="small"
        selectOnBlur={false}
        value={props.value || props.defaultValue}
        multiple={props.multiple}
        selection={true}
        search={true}
        clearable={true}
        fluid={true}
        noResultsMessage={t('GENERAL_SEARCH_NO_RESULTS')}
        onChange={(e, data) => {
          props.onChange(data.value as T);
        }}
        placeholder={props.placeholder}
        disabled={props.disabled}
      />
      {props.sidePopup && <span className="advancedTagFilterPopup">{HelpPopup(props.sidePopup)}</span>}
    </span>
  );
};

export const StringDropdownFilter = (props: NoDefaultDropdownFilterProps<string>) => {
  return DropdownFilter({
    ...props,
    defaultValue: '',
    multiple: false
  });
};

export const ArrayDropdownFilter = <T extends string | number | boolean>(props: NoDefaultDropdownFilterProps<T[]>) => {
  return DropdownFilter({
    ...props,
    defaultValue: [],
    multiple: true
  });
};
