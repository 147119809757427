export const wrapper = (isWrapper?: boolean): React.CSSProperties => ({
  ...(isWrapper ? {} : { padding: '12px' })
});

export const headerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  width: '100%',
  transition: 'height 0.3s ease',
  marginBottom: '8px'
};
export const headerStyleOpen: React.CSSProperties = {};

export const contentStyle: React.CSSProperties = {
  transition: 'height 0.3s ease'
};

export const title: React.CSSProperties = {
  fontSize: '15px',
  fontWeight: 600,
  lineHeight: '24px',
  color: 'rgba(17, 17, 17, 1)'
};
