import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Menu } from 'semantic-ui-react';

import type { FC } from 'react';
import type { ConnectedProps } from 'react-redux';
import type { DropdownItemProps } from 'semantic-ui-react';

import { fetchTemplates } from 'src/actions/templateActions';
import Dropdown from 'src/Components/generic/Dropdown/Dropdown';
import { selectAllowedContentTemplates } from 'src/selectors/rootStateSelectors';

import type { State } from 'src/types/initialState';
import type { TabBarMethod } from 'src/types/TabBar';
import type { Template, TemplateContentJson } from 'src/types/Template';

interface AddNewProps extends ConnectedProps<typeof connector> {
  method: TabBarMethod;
  createContent?: (template?: Template<TemplateContentJson>) => void;
}

const AddNew: FC<AddNewProps> = ({ method, templates, createContent }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onItemClick = useCallback(() => {
    createContent?.();
  }, [createContent]);
  const isSuitableMethod = method === 'tickets' || method === 'infopages';

  useEffect(() => {
    if (isSuitableMethod) {
      dispatch(fetchTemplates());
    }
  }, []);

  if (isSuitableMethod && templates.length) {
    const options: DropdownItemProps[] = templates.map((template, index) => ({
      key: `add-new-template-${index}`,
      text: template.name,
      value: template.id,
      onClick: (_e, { value }) => {
        const template = templates.find((t) => t.id === value);

        createContent?.(template);
      }
    }));

    options.unshift({
      key: 'add-new-template-default',
      text: t('Default content'),
      value: undefined,
      selected: false,
      onClick: onItemClick
    });

    return (
      <Menu.Menu position="left">
        <Dropdown
          item
          simple
          text={t('NEW')}
          direction="right"
          style={{ zIndex: 1000 }}
          options={options}
          icon={
            <span>
              <FontAwesomeIcon id="addNewTicketTabBtn" className="TabBar-New-Icon" icon={faPlus} />
            </span>
          }
        />
      </Menu.Menu>
    );
  }

  return (
    <Menu.Item className="TabBar-Tab" onClick={onItemClick}>
      <span>{t('NEW')}</span>
      <span>
        <FontAwesomeIcon id="addNewTicketTabBtn" className="TabBar-New-Icon" icon={faPlus} />
      </span>
    </Menu.Item>
  );
};

const connector = connect((state: State) => ({
  templates: selectAllowedContentTemplates(state)
}));

export default connector(AddNew);
