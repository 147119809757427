import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'semantic-ui-react';

import type { FC } from 'react';

import { useAppSelector } from 'src/store';

import type { MenuItem } from './UnifiedMenuItem';

interface Props {
  filter: MenuItem;
}

const FilterPopupContent: FC<Props> = ({ filter }) => {
  const { t } = useTranslation();
  const isInfopage = useAppSelector((state) => state.router.location.pathname.startsWith('/infopage'));

  const statues = isInfopage
    ? [
        { id: 0, text: t('ticket_list.status.draft'), value: 'draft' },
        { id: 1, text: t('ticket_list.status.in_review'), value: 'inReview' },
        { id: 2, text: t('ticket_list.status.waiting_to_be_published'), value: 'waitingToBePublished' },
        { id: 3, text: t('ticket_list.status.published'), value: 'published' },
        { id: 4, text: t('ticket_list.status.waste'), value: 'waste' }
      ]
    : [
        { id: 0, text: t('CASE_STATUS_TODO_PLURAL'), value: 'todo' },
        { id: 1, text: t('CASE_STATUS_DOING_PLURAL'), value: 'doing' },
        { id: 2, text: t('CASE_STATUS_DONE_PLURAL'), value: 'done' }
      ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <FontAwesomeIcon icon={filter.icon} style={{ marginRight: '8px' }} />
        {filter.text}
      </div>
      <Divider />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {statues.map((status) => (
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px' }} key={status.id}>
            <div className="list-of-filters-list-item-name">{status.text}</div>
            <div className="list-of-filters-list-item-count">{filter.filterCounts?.[status.value] ?? 0}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterPopupContent;
