import { faWindowRestore } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Popup } from 'semantic-ui-react';

import type { FC } from 'react';

import Button from 'src/Components/generic/Button/Button';
import { TICKET_WINDOW_WIDTH } from 'src/Components/generic/FloatingWindow/constants';
import { addFloatingWindow } from 'src/reducers/floatingWindowsReducer';

import type { FloatingWindowTypes } from 'src/types/FloatingWindow';
import type { Ticket } from 'src/types/Ticket';

interface OpenInWindowButtonProps {
  ticket: Ticket;
}

const OpenInWindowButton: FC<OpenInWindowButtonProps> = ({ ticket }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      dispatch(
        addFloatingWindow({
          id: ticket.id,
          title: ticket.title,
          type: ticket.type as FloatingWindowTypes,
          content: ticket.content,
          width: TICKET_WINDOW_WIDTH,
          data: ticket
        })
      );
    },
    [ticket]
  );

  return (
    <Popup
      trigger={<Button icon={<FontAwesomeIcon icon={faWindowRestore} />} onClick={onClick} />}
      content={t('OPEN_IN_WINDOW')}
    />
  );
};

export default React.memo(OpenInWindowButton);
