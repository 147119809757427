import React from 'react';
import { useTranslation } from 'react-i18next';

import type { FC } from 'react';

import Label from 'src/Components/generic/Label/Label';
import UserAvatar from 'src/Components/User/UserAvatar';
import { getProfileName } from 'src/Components/User/utils';
import { useAppSelector } from 'src/store';

import './TopBarWorkStatusUser.css';

interface Props {
  ticketId: string;
}

const TopBarWorkStatusUser: FC<Props> = ({ ticketId }) => {
  const { t } = useTranslation();
  const workStatus = useAppSelector((state) =>
    state.workStatus.status.find((status) => 'TSK' + status.ticketId === ticketId)
  );
  const workUser = useAppSelector((state) =>
    state.usersList.usersList.find((user) => user.UID === 'USR' + workStatus?.UID)
  );
  const workUserName = getProfileName(workUser);

  if (!workUser) {
    return null;
  }

  return (
    <div className="TopBarWorkStatusUser">
      <UserAvatar user={workUser} size="32" round />
      <span className="TopBarWorkStatusUser__name">{workUserName}</span>
      <Label color="yellow" rounded>
        {t('AUTOMATIC_COMMENT_DOING')}
      </Label>
    </div>
  );
};

export default TopBarWorkStatusUser;
