import { faCloudArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { forwardRef } from 'react';
import Dropzone from 'react-dropzone';
import { Translation } from 'react-i18next';

import type { DropEvent, DropzoneRef } from 'react-dropzone';

import Button from '../generic/Button/Button';
import { onDropRejected } from 'src/Components/ReplyMethods/UploadErrorHandler';
import { MAX_ATTACHMENT_SIZE } from 'src/config';

import './AttachmentDropzone.css';

type UploadFunction = (acceptedOrRejected: File[], event: DropEvent) => void;

interface AttachmentDropzoneProps {
  dropzoneRef?: DropzoneRef | null;
  enabled: boolean;

  onUpload: UploadFunction;
}

const AttachmentDropzone = forwardRef<DropzoneRef, AttachmentDropzoneProps>(function AttachmentDropzone(
  { enabled, onUpload },
  dropzoneRef
) {
  return (
    <Translation ns="translations">
      {(t) =>
        enabled ? (
          <Dropzone
            onDropAccepted={onUpload}
            ref={dropzoneRef}
            onDropRejected={onDropRejected}
            multiple={true}
            maxSize={MAX_ATTACHMENT_SIZE}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                className={classNames('AttachmentDropzone-Basic', isDragActive && 'AttachmentDropzone-Active')}
                {...getRootProps()}
              >
                <input name="attachments" {...getInputProps()} />
                <FontAwesomeIcon size="xl" icon={faCloudArrowUp} />

                <div className="dropzoneTitles">
                  <p className="dropzoneTitles--header">{t('ATTACHMENT_DROPZONE_TITLE')}</p>
                  <p className="dropzoneTitles--subHeader">{t('ATTACHMENT_DROPZONE_DESCRIPTION')}</p>
                </div>

                <Button content="Browse file" />
              </div>
            )}
          </Dropzone>
        ) : null
      }
    </Translation>
  );
});

export default React.memo(AttachmentDropzone);
