import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import type { ReactNode } from 'react';

import { LayoutLeftMenu } from '../LayoutLeftMenu';
import { CommandPalette } from './CommandPalette/CommandPalette';
import { DesktopLayoutRight } from './DesktopLayoutRight';
import { DesktopLayoutWidgetMenu } from './DesktopLayoutWidgetMenu';
import { ListOfPresetFilters } from './ListOfPresetFilters';
import UnifiedMenu from './UnifiedMenu/UnifiedMenu';
import FeatureFlags from 'src/api/FeatureFlags';
import AiAssistantButton from 'src/Components/Case/TopBar/AiAssistantButton';
import { useWidgetView } from 'src/Components/Case/Widget/WidgetViewContext';
import { FocusModeProvider } from 'src/Components/FocusMode/FocusModeContext';
import ActivateFeatureModal from 'src/Components/generic/ActivateFeatureModal';
import Button from 'src/Components/generic/Button/Button';
import EntityViewer from 'src/Components/Management/Entities/EntityViewer';
import TicketsSidebar from 'src/Components/Sidebar/TicketsSidebar';
import SingleLineTicketView from 'src/Components/ticketList/SingleLineTicketView/SingleLineTicketView';
import SingleLineTicketViewInfopages from 'src/Components/ticketList/SingleLineTicketView/SingleLineTicketViewInfopages';
import CaseContainer from 'src/containers/CaseContainer';
import InfopageCaseContainer from 'src/containers/InfopageCaseContainer';
import SettingsContainer from 'src/containers/SettingsContainer';
import TicketTabBarContainer from 'src/containers/TicketTabBarContainer';
import urlActionContainer from 'src/containers/urlActionContainer';
import useIsActiveContentView from 'src/hooks/useIsActiveContentView';
import ProfileDropdown from 'src/ProfileDropdown';
import ReportingPage from 'src/ReportingPage';
import { SINGLE_LINE_VIEW_INFOPAGES_PATH, SINGLE_LINE_VIEW_TICKETS_PATH } from 'src/Utilities/constants';

import type { State } from 'src/types/initialState';

import './DesktopLayout.css';

const _App = (props: any) => (
  <FocusModeProvider>
    <div className="layout-desktop">{props.children}</div>
  </FocusModeProvider>
);

const _LayoutTopBar = () => {
  const { toggleWidgetView } = useWidgetView();
  return (
    <div className="layout-desktop-topmenubar">
      <div className="layout-desktop-topmenubar-logo logo" />
      <div className="layout-desktop-topmenubar-search">
        <CommandPalette />
      </div>
      <div className="layout-desktop-topmenubar-profile">
        <Button onClick={() => toggleWidgetView()} size="lg" icon={<FontAwesomeIcon icon={faLayerGroup} />} />
        <ProfileDropdown />
      </div>
    </div>
  );
};

const _LayoutColumns = (props: any) => (
  <div className="layout-desktop-columns">
    {props.children.map((child: ReactNode) => (
      <>{child}</>
    ))}
  </div>
);
const _LayoutSideMenu = () => <LayoutLeftMenu />;

const _LayoutFilters = () => <ListOfPresetFilters />;

const _LayoutTicketList = () => {
  const shouldHide = useSelector<State>(
    (state) =>
      state.router.location?.pathname.includes('/settings') || state.router.location?.pathname.includes('/entities')
  );

  if (shouldHide) {
    return null;
  }
  return (
    <div className="layout-desktop-ticketlist">
      <TicketsSidebar />
    </div>
  );
};
// TODO
const mobileMode = false;

const _LayoutContents = () => (
  <div className="layout-desktop-contents">
    <Route
      path="/case/:id"
      exact={true}
      render={(routeProps) => (
        <>
          <TicketTabBarContainer contentType="tickets" />
          <CaseContainer {...routeProps} key={routeProps.match.params.id} />
        </>
      )}
    />
    <Route
      path={SINGLE_LINE_VIEW_TICKETS_PATH}
      exact
      render={(routeProps) => <SingleLineTicketView {...routeProps} />}
    />
    <Route
      path={SINGLE_LINE_VIEW_INFOPAGES_PATH}
      exact
      render={(routeProps) => <SingleLineTicketViewInfopages {...routeProps} />}
    />
    <Route path="/reporting" component={ReportingPage} exact={true} />
    <Route
      path={['/settings/:pageName', '/settings']}
      render={(...props) => <SettingsContainer noMenuContainer={false} {...(props as any)} />}
    />
    {FeatureFlags.isFlagOn('ENABLE_ENTITY_VIEWER') && <Route path="/entities/" component={EntityViewer} />}
    {!mobileMode && FeatureFlags.isFlagOn('ENABLE_KNOWLEDGE_BASE') && (
      <Route
        path="/infopage/:id"
        render={(routeProps) => (
          <>
            <TicketTabBarContainer contentType="infopages" />
            <InfopageCaseContainer {...routeProps} key={routeProps.match.params.id} />
          </>
        )}
      />
    )}
    {!FeatureFlags.isFlagOn('ENABLE_KNOWLEDGE_BASE') && <Route path="/feature" component={ActivateFeatureModal} />}

    <Route path="/actions/:type" component={urlActionContainer} />

    <Route path="/" exact={true} render={() => <TicketTabBarContainer contentType="tickets" />} />
    <Route path="/infopage" exact={true} render={() => <TicketTabBarContainer contentType="infopages" />} />
  </div>
);

const _LayoutRightMenu = () => {
  const isActiveContentView = useIsActiveContentView();
  const { isRightSidebarOpen } = useWidgetView();

  return (
    <AnimatePresence>
      {isActiveContentView && isRightSidebarOpen && (
        <motion.div
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: 1, width: 'initial' }}
          exit={{ opacity: 0, width: 0 }}
        >
          <div className="layout-desktop-right-side-menu">
            <DesktopLayoutRight />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const _LayoutWidgetMenu = () => {
  const isActiveContentView = useIsActiveContentView();
  return isActiveContentView && <DesktopLayoutWidgetMenu />;
};

const _UnifiedMenuAndFilters = () => <UnifiedMenu />;

// const initialLayout = {
//   leftMenu: {
//     isMinimised: true
//   },
//   filters: {
//     isMinimised: true
//   },
//   lists: {
//     isMinimised: false
//   },
//   contents: {
//     isMinimised: false
//   },
//   rightMenu: {
//     isMinised: false
//   }
// };

const DesktopLayout = () => {
  const [isUnified, setIsUnified] = useState(true);
  const { isSingleWidgetView } = useWidgetView();

  return (
    <_App>
      <button onClick={() => setIsUnified(!isUnified)} style={{ display: 'none' }}>
        Switch between Unified menu and Menu + Filter presets separately
      </button>
      <_LayoutTopBar />
      <_LayoutColumns>
        {isUnified && <_UnifiedMenuAndFilters />}
        {!isUnified && <_LayoutSideMenu />}
        {!isUnified && <_LayoutFilters />}
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginRight: '20px',
            backgroundColor: 'white',
            borderRadius: '25px',
            maxWidth: `calc(100vw - ${isSingleWidgetView ? '156px' : '78px'})`
          }}
        >
          <_LayoutTicketList />
          <_LayoutContents />
          <_LayoutRightMenu />
        </div>
        <_LayoutWidgetMenu />
      </_LayoutColumns>

      {FeatureFlags.isFlagOn('ENABLE_AI_ASSISTANT') && <AiAssistantButton />}
    </_App>
  );
};

// return (
//   <div className='wrapper'>
//     <Grid
//       verticalAlign="middle"
//       className="main-topbar"
//       style={{
//         ...(!!getUrlSearchParam('hideNavigation') && { display: 'none' }),
//       }}
//     >
//       <Grid.Row>
//         <Grid.Column floated="left" verticalAlign="middle">
//           <LeftSideMenuDropdown />
//         </Grid.Column>
//         <Grid.Column width={3} floated="left" textAlign="left">
//           <div className="logo" />
//         </Grid.Column>
//         <Grid.Column computer={8} tablet={4} mobile={1} />
//         <Grid.Column width={4} floated="right" textAlign="right">
//           <Grid>
//             <Grid.Row columns={2}>
//               <Grid.Column width={12} verticalAlign="middle" style={{ color: 'white' }} />
//                 <Grid.Column width={4}>
//                   <RightSideProfileDropdown />
//                 </Grid.Column>
//             </Grid.Row>
//           </Grid>
//         </Grid.Column>
//       </Grid.Row>
//     </Grid>
//     <ErrorBoundary>
//       <div className='main'>
//         {/* {props.children} */}
//       </div>
//     </ErrorBoundary>
//   </div>
// )

export default DesktopLayout;
