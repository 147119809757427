import {
  faCalendar,
  faCircleQuestion,
  faCommentDots,
  faCommentQuestion,
  faFolderOpen,
  faHandshake,
  faIdCard,
  faLink,
  faLinkSimple,
  faMicrochipAi,
  faNewspaper,
  faPaperclip,
  faPenToSquare,
  faPhone,
  faTag,
  faTriangleExclamation,
  faUserPlus
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import type { SizeProp } from '@fortawesome/fontawesome-svg-core';
import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';

const wrapIcon = (icon: IconDefinition, size: SizeProp = 'lg') => {
  return <FontAwesomeIcon size={size} icon={icon} />;
};

export const widgetIconMapping = {
  Tags: faTag,
  Delegations: faUserPlus,
  EIdentification: faHandshake,
  RescheduleCallback: faCalendar,
  AttachEntityById: faPenToSquare,
  FieldSets: faFolderOpen,
  SuggestedArticles: faNewspaper,
  CaseChannel: faCommentQuestion,
  TicketRelations: faLink,
  CustomerInfo: faIdCard,
  Attachments: faPaperclip,
  Priority: faTriangleExclamation,
  Surveys: faCommentDots,
  ChatGPT: faMicrochipAi,
  ExternalLinks: faLinkSimple,
  Transcriptions: faPhone
};

export const getWidgetIcon = (name: string) => {
  if (widgetIconMapping[name as keyof typeof widgetIconMapping]) {
    return wrapIcon(widgetIconMapping[name as keyof typeof widgetIconMapping]);
  }

  // Default icon
  return wrapIcon(faCircleQuestion);
};
