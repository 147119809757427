import { faInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iziToast from 'izitoast';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { generateTicketRelevantInfo } from 'src/actions/openAIActions';
import Button from 'src/Components/generic/Button/Button';
import { useAppThunkDispatch } from 'src/store';
import { convertCaseNaming, getLastIncommingComment } from 'src/Utilities/helper';

import type { Ticket } from 'src/types/Ticket';

interface RelevantInformationProps {
  task: Ticket;
}

export default ({ task }: RelevantInformationProps) => {
  const { t } = useTranslation();
  const dispatchThunk = useAppThunkDispatch();
  const [isGenerating, setGenerating] = useState(false);

  const handleTicketRelevantInfo = async () => {
    setGenerating(true);
    const commentPayload = getLastIncommingComment(task);
    if (commentPayload.content) {
      await dispatchThunk(
        generateTicketRelevantInfo({
          contentId: convertCaseNaming(task.id, 'number', 'task') as number,
          content: commentPayload.content,
          isHtml: commentPayload.isHtml,
          commentId: commentPayload.commentId
        })
      );
    } else {
      iziToast.info({
        message: t('widgets.chatGPT.toasts.no_incoming')
      });
    }
    setGenerating(false);
  };

  return (
    <Button
      fullWidth
      disabled={isGenerating}
      onClick={() => handleTicketRelevantInfo()}
      type="primary"
      iconLeft={<FontAwesomeIcon icon={faInfo} />}
      isLoading={isGenerating}
    >
      {t('widgets.chatGPT.buttons.rel_info')}
    </Button>
  );
};
