import { faNotes, faTimer, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import sanitizeHTML from 'sanitize-html';
import { Table } from 'semantic-ui-react';

import Button from '../generic/Button/Button';
import { ModalWindow } from './DisplayWindow';

import type { DraftEntry, VersionControlClient } from 'src/api/VersionControl';

export type ViewMode = 'versions' | 'drafts' | null;

type VersionViewerProps = {
  dataSource: VersionControlClient;
  id: number;
  channelId?: number;
  mode: ViewMode;
  onClose?: () => void;
  onClickLoad?: (mode: ViewMode, id: number) => void;
  parseContent?: (content: string) => string;
};

function frag(data: any, maxlength: number): string {
  if (typeof data !== 'string') return '';

  const text = sanitizeHTML(data, { allowedTags: [], allowedAttributes: {} });

  if (text.length > maxlength) {
    return text.substring(0, maxlength) + '...';
  }
  return text;
}

type RowEntry = DraftEntry & {
  time: string;
};

function unix2date(unix: number): string {
  const d = new Date(unix * 1000);
  return d.toLocaleString();
}

const VersionViewer: React.FC<VersionViewerProps> = ({
  id,
  channelId,
  mode,
  dataSource,
  onClose,
  onClickLoad,
  parseContent
}) => {
  const { t } = useTranslation();
  const [rows, setRows] = React.useState<RowEntry[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (mode === null) {
      if (rows.length > 0) {
        setRows([]);
      }
      return;
    }
    if (mode === 'drafts') {
      dataSource.loadDraftHistory(id, channelId).then((drafts) => {
        const rows = drafts.map((r) => {
          return { ...r, time: unix2date(r.created) };
        });
        setRows(rows);
        setLoading(false);
      });
      return;
    }
    if (mode === 'versions') {
      dataSource.loadVersionHistory(id).then((data) => {
        const newRows = data.map((r) => {
          return {
            id: r.id,
            content: r.previousContent,
            UID: r.UID,
            username: r.username,
            created: r.created,
            time: unix2date(r.created)
          };
        });
        setRows(newRows);
        setLoading(false);
      });
      return;
    }
  }, [id, mode]);
  if (mode === null) return null;

  const title = mode === 'drafts' ? t('AVAILABLE_DRAFTS') : t('PREVIOUS_VERSIONS');

  return (
    <ModalWindow
      title={title}
      onClickClose={() => {
        if (onClose) {
          onClose();
        }
      }}
    >
      {loading && <b>Loading...</b>}
      {rows.length > 0 && (
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <FontAwesomeIcon icon={faUser} />
                {t('USER')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FontAwesomeIcon icon={faTimer} />
                {t('SAVED_ON')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FontAwesomeIcon icon={faNotes} />
                {t('CONTENT')}
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {rows.map((row) => (
              <Table.Row key={row.id}>
                <Table.Cell>{row.username}</Table.Cell>
                <Table.Cell>{row.time}</Table.Cell>
                <Table.Cell>{frag(parseContent ? parseContent(row.content) : row.content, 120)}</Table.Cell>
                <Table.Cell>
                  <Button
                    size="xs"
                    circular
                    content={t('VIEW')}
                    type="positive"
                    onClick={() => {
                      if (onClickLoad) {
                        onClickLoad(mode, row.id);
                      }
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </ModalWindow>
  );
};

export default React.memo(VersionViewer);
