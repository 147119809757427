import { enUS, fi, fr } from 'date-fns/locale';

/**
 * Returns the appropriate date-fns locale object based on language code
 * @param language - The language code (e.g., 'en', 'fi')
 * @returns date-fns locale object
 */
export const getLocale = (language: string) => {
  switch (language.toLowerCase()) {
    case 'fi':
      return fi;
    case 'fr':
      return fr;
    case 'en':
    default:
      return enUS;
  }
};
