import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AutoSizer, Column, Table as VirtualizedTable } from 'react-virtualized';
import { AccordionContent, Checkbox, Icon, Label } from 'semantic-ui-react';

import type { PersonalData, Priority, UserWithProfile } from '@eeedo/types';
import type { TableCellProps } from 'react-virtualized';
import type { SemanticICONS } from 'semantic-ui-react';

import * as styles from './SingleLineAccordionTab.style';
import AccordionHeader from 'src/Components/Case/AccordionHeader';
import WorkStatusImageContainer from 'src/containers/WorkStatusImageContainer';
import { StaticTabs } from 'src/types/TicketList';
import { channelIconsMap, statusTranslationsMap } from 'src/Utilities/constants';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';

import type { State } from 'src/types/initialState';
import type { TicketListTicket } from 'src/types/Ticket';

interface SingleLineAccordionTabProps {
  index: number;
  status: keyof typeof statusTranslationsMap;
  tickets: TicketListTicket[];
  activeIndex: number;
  priorities: Priority[];
  user: UserWithProfile;
  userData: PersonalData;
}

const SingleLineAccordionTab: React.FC<SingleLineAccordionTabProps> = ({
  index,
  status,
  tickets,
  activeIndex,
  priorities,
  user,
  userData
}) => {
  const { t } = useTranslation();
  const [checkedRows, setCheckedRows] = useState<Record<number, boolean>>({});

  const onCheckboxChange = (rowIndex: number, checked: boolean) => {
    setCheckedRows((prev) => ({ ...prev, [rowIndex]: checked }));
  };

  // Cell renderer functions for each column
  const renderCheckboxCell = ({ rowIndex }: TableCellProps) => (
    <Checkbox checked={!!checkedRows[rowIndex]} onChange={(_, data) => onCheckboxChange(rowIndex, data.checked!)} />
  );

  const renderTaskTypeCell = ({ cellData }: TableCellProps) => <Label>{cellData}</Label>;

  const renderTitleCell = ({ cellData }: TableCellProps) => <div>{cellData}</div>;

  const renderCreatedCell = ({ cellData }: TableCellProps) => (
    <div>{getPrettyDate(cellData, { format: DATE_TIME_FORMAT }) || '-'}</div>
  );

  const renderDueDateCell = ({ cellData }: TableCellProps) => (
    <div>{getPrettyDate(cellData, { format: DATE_TIME_FORMAT }) || '-'}</div>
  );

  const renderChannelCell = ({ cellData }: TableCellProps) => (
    <div style={styles.channelIconWrapper}>
      <Icon name={channelIconsMap[cellData]} />
    </div>
  );

  const renderPriorityCell = ({ cellData }: TableCellProps) => {
    const priority = priorities.find((p) => p.value === cellData);
    const iconStyle = { color: priority?.color };
    return (
      priority && (
        <div style={styles.priorityIconWrapper}>
          <Icon name={priority.icon as SemanticICONS} style={iconStyle} />
          {t(priority.text)}
        </div>
      )
    );
  };

  const renderActionsCell = ({ rowData }: TableCellProps) => (
    <WorkStatusImageContainer
      user={user}
      id={rowData.id}
      userData={userData}
      showStartWorkingOnButton={rowData.status === 'todo'}
    />
  );

  const commonColumnProps = () => ({
    headerStyle: styles.headerCell,
    style: styles.columnCell
  });

  return (
    <AccordionHeader
      as="h4"
      title={t(statusTranslationsMap[status])}
      label={tickets.length}
      active={activeIndex === index}
      divider={false}
    >
      <AccordionContent style={{ padding: '0 24px' }} active={activeIndex === index}>
        <div style={styles.tableWrapper}>
          <AutoSizer>
            {({ width, height }) => (
              <VirtualizedTable
                width={width}
                height={height}
                headerHeight={44}
                rowHeight={52}
                rowCount={tickets.length}
                rowGetter={({ index }) => tickets[index]}
              >
                <Column
                  dataKey="checkbox"
                  width={25}
                  flexShrink={0}
                  flexGrow={0}
                  cellRenderer={renderCheckboxCell}
                  {...commonColumnProps()}
                />
                <Column
                  label={t('Task Type')}
                  dataKey="taskType"
                  width={0}
                  flexShrink={1}
                  flexGrow={1}
                  cellRenderer={renderTaskTypeCell}
                  {...commonColumnProps()}
                />
                <Column
                  label={t('Title')}
                  dataKey="title"
                  width={0}
                  flexShrink={1}
                  flexGrow={1}
                  cellRenderer={renderTitleCell}
                  {...commonColumnProps()}
                />
                <Column
                  label={t('Created')}
                  dataKey="created"
                  width={0}
                  flexShrink={1}
                  flexGrow={1}
                  cellRenderer={renderCreatedCell}
                  {...commonColumnProps()}
                />
                <Column
                  label={t('Due Date')}
                  dataKey="dueDate"
                  width={0}
                  flexShrink={1}
                  flexGrow={1}
                  cellRenderer={renderDueDateCell}
                  {...commonColumnProps()}
                />
                <Column
                  label={t('Channel')}
                  dataKey="channel"
                  width={100}
                  flexShrink={0}
                  flexGrow={0}
                  cellRenderer={renderChannelCell}
                  {...commonColumnProps()}
                />
                <Column
                  label={t('Priority')}
                  dataKey="priority"
                  width={0}
                  flexShrink={1}
                  flexGrow={1}
                  cellRenderer={renderPriorityCell}
                  {...commonColumnProps()}
                />
                <Column
                  label={t('Actions')}
                  dataKey="actions"
                  width={60}
                  flexShrink={0}
                  flexGrow={0}
                  cellRenderer={renderActionsCell}
                  {...commonColumnProps()}
                />
              </VirtualizedTable>
            )}
          </AutoSizer>
        </div>
      </AccordionContent>
    </AccordionHeader>
  );
};

const connector = connect((state: State) => {
  const activeTab = Object.values(state.ticketListTabs).find((tab) => tab.activeTab);

  return {
    activeIndex: state.ticketListTabs[activeTab?.id ?? StaticTabs.MAIN_VIEW].accordionIndex,
    priorities: state.ticketPriorities,
    user: state.usersList.usersList.find((user) => user.UID === state.userData.UID)!,
    userData: state.userData
  };
});

export default connector(SingleLineAccordionTab);
