import React from 'react';
import { Accordion } from 'semantic-ui-react';

import type { FC } from 'react';

import SingleLineAccordionTab from './SingleLineAccordionTab';

import type { DropdownOption } from 'src/types/Info';
import type { TicketListTicket } from 'src/types/Ticket';

import './SingleLineTicketView.css';

export type AccordionTab = {
  index: number;
  status: 'draft' | 'inReview' | 'waitingToBePublished' | 'published' | 'waste' | 'todo' | 'doing' | 'done';
  tickets: TicketListTicket[];
};

interface SingleLineTicketViewContentProps {
  accordionTabs: AccordionTab[];
  dropdownOptions: DropdownOption[];
}

const SingleLineTicketViewContent: FC<SingleLineTicketViewContentProps> = ({ accordionTabs }) => {
  return (
    <Accordion fluid styled style={{ borderRadius: 0, flex: 1, height: '100%' }}>
      {accordionTabs.map((tab) => (
        <SingleLineAccordionTab index={tab.index} status={tab.status} tickets={tab.tickets} />
      ))}

      {/* Commented out for the future development of ticket TSK43631 - not fitting current layout */}
      {/* <AccordionTitle>
        <ButtonGroup color="blue">
          <Button basic color="blue">
            Perform on selected
          </Button>
          <Dropdown className="button icon" floating options={dropdownOptions} trigger={<></>} />
        </ButtonGroup>
      </AccordionTitle> */}
    </Accordion>
  );
};

export default SingleLineTicketViewContent;
