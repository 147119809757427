import format from 'date-fns/format';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import setSeconds from 'date-fns/setSeconds';
import React, { useCallback } from 'react';

import './TimeInput.css';

import type { ComponentProps, FC } from 'react';

import Dropdown from 'src/Components/generic/Dropdown/Dropdown';
import Input from 'src/Components/generic/Input/Input';

type OnInputChange = NonNullable<ComponentProps<typeof Dropdown>['onChange']>;
// type ZeroToThree = '0' | '1' | '2' | '3';
type ZeroToFive = '0' | '1' | '2' | '3' | '4' | '5';
type ZeroToNine = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
// type OneToTwentyFour = `${'0' | '1'}${ZeroToNine}` | `2${ZeroToThree}` | '00';
type MinutesString = `${ZeroToFive}${ZeroToNine}`;

interface TimeInputProps {
  date: Date;
  // minTime?: `${OneToTwentyFour}:${MinutesString}`;
  minTime?: string;
  shiftSeconds?: MinutesString;
  fluid?: boolean;

  onChange(date: Date): void;
}

const timeOptions = new Array(24)
  .fill('')
  .map((_, index) => `${index}`.padStart(2, '0'))
  .map((hour) => new Array(12).fill('').map((_, index) => `${hour}:${`${index * 5}`.padStart(2, '0')}`))
  .flat()
  .map((time) => ({ key: time, text: time, value: time }));

const TimeInput: FC<TimeInputProps> = ({ date, minTime, shiftSeconds, fluid, onChange }) => {
  const onInputChange = useCallback<OnInputChange>(
    (_, { value }) => {
      const [hours, minutes] = (value as string).split(':') ?? [];
      let newDate = setHours(date, Number(hours));
      newDate = setMinutes(newDate, Number(minutes));
      if (shiftSeconds) {
        newDate = setSeconds(newDate, Number(shiftSeconds));
      }

      onChange(newDate);
    },
    [date, onChange]
  );

  const options = minTime
    ? timeOptions.slice(timeOptions.findIndex((option) => option.value === minTime))
    : timeOptions;

  return (
    <Dropdown
      icon=""
      fluid={fluid}
      scrolling
      options={options}
      value={format(date, 'HH:mm')}
      onChange={onInputChange}
      trigger={<Input value={format(date, 'HH:mm')} type="time" icon="clock outline" />}
    />
  );
};

export default React.memo(TimeInput);
