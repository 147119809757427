import React from 'react';
import { Accordion } from 'semantic-ui-react';

import type { PersonalData } from '@eeedo/types';

import Collapsible from '../generic/Collapsible/Collapsible';
import Label from '../generic/Label/Label';
import Section from '../generic/Section/Section';
import AttachmentItem from './AttachmentItem';

import type { AttachmentEdit } from './AttachmentItem';
import type { Attachment } from 'src/types/Ticket';

interface AttachmentsAccordionProps {
  title: string;
  initialOpen: boolean;
  attachments: Attachment[];
  fullAttachments: Attachment[];
  personalData: PersonalData;

  openFileDialog?(): void;
  uploadFile?(files: File[]): void;
  deprecateAttachment?(id: string): void;
  unDeprecateAttachment?(id: string): void;
  editAttachment?(id: string, body: AttachmentEdit): void;
}

const AttachmentsAccordion = ({
  title,
  attachments,
  initialOpen,
  personalData,
  fullAttachments,

  editAttachment,
  deprecateAttachment,
  unDeprecateAttachment,
  openFileDialog
}: AttachmentsAccordionProps) => {
  return (
    <Collapsible
      isNoWrapper
      openByDefault={initialOpen}
      title={
        <>
          {title} <Label>{attachments.length}</Label>
        </>
      }
    >
      <Accordion.Content>
        <Section gap={8} wrap="wrap" direction="column">
          {attachments.map((attachment) => (
            <AttachmentItem
              key={`attachment-item-${attachment.id}`}
              id={attachment.id}
              uri={attachment.uri}
              size={attachment.size}
              uploaded={attachment.uploaded}
              fileName={attachment.fileName}
              deprecated={attachment.deprecated}
              previewUri={attachment.previewUri}
              isQuarantined={attachment.isQuarantined}
              attachments={fullAttachments}
              personalData={personalData}
              onEdit={editAttachment}
              onDeprecate={deprecateAttachment}
              onUnDeprecate={unDeprecateAttachment}
              openFileDialog={openFileDialog}
            />
          ))}
        </Section>
      </Accordion.Content>
    </Collapsible>
  );
};

export default React.memo(AttachmentsAccordion);
