import { FilterPresetType } from '@eeedo/types';
import {
  faArrowUpRightFromSquare,
  faBookOpen,
  faChartColumn,
  faGear,
  faGlobe,
  faHouse,
  faTableList,
  faUser,
  faUsers
} from '@fortawesome/pro-regular-svg-icons';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'semantic-ui-react';

import UnifiedMenuItem from './UnifiedMenuItem';
import FeatureFlags from 'src/api/FeatureFlags';
import ModalLoaderComponent from 'src/Components/Loader/ModalLoader';
import useFilterPresets from 'src/Components/ticketList/FilterPresetsWidget/useFilterPresets';
import ExportDataContainer from 'src/containers/ExportDataContainer';

import type { MenuItem } from './UnifiedMenuItem';

const UnifiedMenu = () => {
  const { t } = useTranslation();

  const [exportDataModalOpen, setIsExportDataModalOpen] = useState(false);
  const setExportDataModalOpen = useCallback(() => setIsExportDataModalOpen(true), [setIsExportDataModalOpen]);
  const setExportDataModalClosed = useCallback(() => setIsExportDataModalOpen(false), [setIsExportDataModalOpen]);
  const [sortedFilterPresets, , applyFilter] = useFilterPresets();

  const menuItems: (MenuItem & { action?: (...args: any[]) => void })[] = useMemo(
    () => [
      { icon: faHouse, text: 'Main view', href: '/' },
      { icon: faBookOpen, text: 'Knowledge base', href: '/infopage' },
      { icon: faArrowUpRightFromSquare, text: 'Export data', action: setExportDataModalOpen },
      { icon: faChartColumn, text: 'Reporting', href: '/reporting' },
      { icon: faTableList, text: 'Aggregate view', href: '/aggregate' },
      { icon: faGear, text: 'Settings', href: '/settings' },
      ...(FeatureFlags.isFlagOn('ENABLE_ENTITY_VIEWER')
        ? [{ icon: faUsers, text: t('entityViewer.entity_view'), href: '/entities' }]
        : [])
    ],
    [setExportDataModalOpen]
  );

  const filtersItems = useMemo(
    () =>
      sortedFilterPresets.map((filterPreset) => ({
        id: filterPreset.id,
        icon: filterPreset.type === FilterPresetType.global ? faGlobe : faUser,
        text: filterPreset.name,
        filterCounts: filterPreset.counts,
        isActive: filterPreset.isSelected,
        action: () => applyFilter(filterPreset.id)
      })),
    [sortedFilterPresets]
  );

  return (
    <div className="layout-desktop-unified-menu">
      <div className="layout-desktop-unified-menu_items">
        {menuItems.map((item) => (
          <UnifiedMenuItem item={item} key={item.text} />
        ))}
      </div>

      {!!filtersItems.length && (
        <>
          <Divider />

          <div className="layout-desktop-unified-menu_items">
            {filtersItems.map((item) => (
              <UnifiedMenuItem item={item} key={item.id ?? item.text} />
            ))}
          </div>
        </>
      )}

      {exportDataModalOpen && (
        <React.Suspense fallback={<ModalLoaderComponent />}>
          <ExportDataContainer handleClose={setExportDataModalClosed} isOpen={exportDataModalOpen} />
        </React.Suspense>
      )}
    </div>
  );
};

export default UnifiedMenu;
