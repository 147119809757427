import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash-es';
import React, { useCallback, useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Segment } from 'semantic-ui-react';

import type {
  Category,
  Channel,
  Direction,
  PersonalData,
  Priority,
  SortBy,
  TabFilter,
  Tag,
  TicketType,
  TicketTypeMetadata,
  UserWithProfile
} from '@eeedo/types';
import type { FC } from 'react';

import Sorting from '../Filter/Sorting';
import Button from '../generic/Button/Button';
import Filter from './Filter';
import * as styles from './Filter.style';
import FilterPresetsWidget from './FilterPresetsWidget/FilterPresetsWidget';
import { getURLFilterParams } from 'src/Utilities/helper';

import type { LocalFiltersState } from './Filter';
import type { IFilterItem } from 'src/types/Filter';
import type { State } from 'src/types/initialState';
import type { MenuTab } from 'src/types/MenuTab';
import type { ContentTypesFields } from 'src/types/Ticket';
import type { TicketListTab } from 'src/types/TicketList';

export interface IFilterWrapperStateToProps {
  ticketTypesMetadata: TicketTypeMetadata[];
  categories: Category[];
  personalData: PersonalData;
  ticketTypes: TicketType[];
  channels: Channel[];
  tags: Tag[];
  tab: MenuTab;
  usersList: UserWithProfile[];
  priorities: Priority[];
  filters?: TabFilter;
  isUsingUrlParams: boolean;
  isFullWidthContainer: boolean;
  contentType?: ContentTypesFields;
}

export interface IFilterWrapperDispatchToProps {
  setFilter(args: IFilterItem): void;

  clearAllFilters?: (id: string) => void;
  setSorting?: (sorting: SortBy, direction: Direction) => void;
}

type IProps = IFilterWrapperStateToProps & IFilterWrapperDispatchToProps;

type FilterParams = 'title' | 'originalContact' | 'lastContactAddress';

const FilterWrapper: FC<IProps> = (props) => {
  const getParam = (param: FilterParams) => {
    if (props.isUsingUrlParams) {
      return getURLFilterParams(window.location.search)[param] || props.tab?.filters?.[param];
    } else {
      return props.filters?.[param];
    }
  };

  const [localFiltersState, setLocalFiltersState] = useState<LocalFiltersState>({
    titleFilter: getParam('title'),
    originalContactFilter: getParam('originalContact'),
    lastContactAddressFilter: getParam('lastContactAddress')
  });

  const [isExtraOptionsOpen, setIsExtraOptionsOpen] = useState(false);

  const toggleExtraOptions = () => {
    setIsExtraOptionsOpen(!isExtraOptionsOpen);
  };

  const changeFilter = (value: any, type: keyof TabFilter) => {
    if (props.isUsingUrlParams) {
      if (value === '' || Number.isNaN(value) || (Array.isArray(value) && value.length === 0)) {
        value = undefined;
      }
    }

    props.setFilter({
      id: props.tab.id,
      parameter: type,
      value: value
    });
  };

  const freeFilter = (value: string | boolean | undefined, property: FilterParams) => {
    props.setFilter({
      id: props.tab.id,
      parameter: property,
      value: value
    });
  };

  const debounceFilter = debounce(
    (value: string | boolean | undefined, property: FilterParams) => freeFilter(value, property),
    750
  );

  const onChangeFilter = (filter: FilterParams, prop: keyof LocalFiltersState, value: string) => {
    setLocalFiltersState({ ...localFiltersState, [prop]: value });
    debounceFilter(value === '' ? undefined : value, filter);
  };

  const clearFilters = useCallback(() => {
    setLocalFiltersState({});
    setIsExtraOptionsOpen(false);
    props.clearAllFilters?.(props.tab.id);
  }, [props.tab.id]);

  const activeFilterId = useSelector((state: State) => state.filterPresets.activeId);

  useEffect(() => {
    setLocalFiltersState({
      titleFilter: getParam('title'),
      originalContactFilter: getParam('originalContact'),
      lastContactAddressFilter: getParam('lastContactAddress')
    });
  }, [activeFilterId]);

  return (
    <Translation ns="translations">
      {(tr) => (
        <Segment className="filterComponent" style={styles.filterComponent(props.isFullWidthContainer)}>
          <div style={styles.mainControlsWrapper}>
            <Button
              onClick={clearFilters}
              iconLeft={<FontAwesomeIcon icon={faXmark} />}
              content={tr('filter.clear_short')}
              type="error"
            />
            {props.contentType === 'tickets' && (
              <Sorting
                sorting={(props.tab as TicketListTab).sorting}
                direction={(props.tab as TicketListTab).direction}
                onChange={props.setSorting!}
              />
            )}

            <FilterPresetsWidget clearFilters={clearFilters} />
          </div>
          <Filter
            ticketTypesMetadata={props.ticketTypesMetadata}
            categories={props.categories}
            personalData={props.personalData}
            ticketTypes={props.ticketTypes}
            channels={props.channels}
            tags={props.tags}
            usersList={props.usersList}
            priorities={props.priorities}
            filters={props.filters ?? {}}
            contentType={props.contentType}
            isExtraOptionsOpen={isExtraOptionsOpen}
            localFiltersState={localFiltersState}
            toggleExtraOptions={toggleExtraOptions}
            changeFilter={changeFilter}
            onChangeFilter={onChangeFilter}
            prefixId=""
          />
        </Segment>
      )}
    </Translation>
  );
};

export default FilterWrapper;
