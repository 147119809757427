import React from 'react';
import { Label } from 'semantic-ui-react';

import type { Tag as TagData } from '@eeedo/types';
import type { FC, SyntheticEvent } from 'react';

import './Tag.css';

import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface TagProps {
  tag: TagData;
  onClose?: React.EventHandler<SyntheticEvent>;
}

const Tag: FC<TagProps> = ({ tag, onClose, ...props }) => (
  <Label
    className="eeedoTag"
    style={{ backgroundColor: tag.styles?.bgColor, color: tag.styles?.fontColor }}
    tag={false}
    {...props}
  >
    {tag.name}
    {onClose && <FontAwesomeIcon icon={faClose} onClick={onClose} size="lg" />}
  </Label>
);

export default React.memo(Tag);
