import { createReducer } from '@reduxjs/toolkit';

import type { RoleWithPermission } from '@eeedo/types';

import { loadRolesSuccess, updateRoleSuccess } from 'src/actions/rolesActions';

type RolesState = {
  roles: RoleWithPermission[];
};

const initialState: RolesState = {
  roles: []
};

const rolesReducer = createReducer(initialState.roles, (builder) => {
  builder.addCase(loadRolesSuccess, (state, action) => {
    return action.payload;
  });

  builder.addCase(updateRoleSuccess, (state, action) => {
    return state.map((role) => {
      if (role.id !== action.payload.id) return role;
      return action.payload;
    });
  });
});

export default rolesReducer;
