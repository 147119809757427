import classNames from 'classnames';
import React from 'react';
import { Popup as SemanticPopup } from 'semantic-ui-react';

import type { FC } from 'react';
import type { PopupProps } from 'semantic-ui-react';

import './Popup.css';

interface Popup extends PopupProps {
  noPadding?: boolean;
  delay?: number;
}
const Popup: FC<Popup> = ({ children, className, delay = 0, noPadding, ...res }) => {
  const [isContentVisible, setIsContentVisible] = React.useState<boolean>(false);
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const handleOpenContent = () => {
    if (delay > 0) {
      timeoutRef.current = setTimeout(() => {
        setIsContentVisible(true);
      }, delay);
    } else {
      setIsContentVisible(true);
    }
  };

  const handleCloseContent = React.useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsContentVisible(false);
  }, []);

  return (
    <SemanticPopup
      className={classNames('eeedoPopup', className, { noPadding: !!noPadding })}
      open={isContentVisible}
      onOpen={() => handleOpenContent()}
      onClose={() => handleCloseContent()}
      {...res}
    >
      {children}
    </SemanticPopup>
  );
};

export default Popup;
