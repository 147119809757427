import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Icon } from 'semantic-ui-react';

import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import type { SemanticICONS } from 'semantic-ui-react';

import { useWidgetView } from './Widget/WidgetViewContext';
import Label from 'src/Components/generic/Label/Label';

import './AccordionHeader.css';

interface AccordionHeaderProps {
  id?: string;
  icon?: SemanticICONS;
  as: string;
  title: string;
  label?: string | number;
  active: boolean;
  accordionIcons?: AccordionIcons;
  customIconClassname?: string;
  children?: React.ReactNode;
  divider?: boolean;
  isWidget?: boolean;

  render?(...args: any[]): any;
}

interface AccordionIcons {
  active: SemanticICONS;
  passive: SemanticICONS;
}

const AccordionHeader: React.FC<AccordionHeaderProps> = (props) => {
  const { isSingleWidgetView } = useWidgetView();
  const [active, setActive] = useState<boolean>((props.isWidget && isSingleWidgetView) || props.active);
  const prevIdRef = useRef<string | undefined>(props.id);

  const handleToggle = () => {
    if (props.isWidget && isSingleWidgetView) return;
    setActive((prevActive) => !prevActive);
  };

  useEffect(() => {
    if (props.id && prevIdRef.current !== props.id) {
      setActive(props.active);
    }
    prevIdRef.current = props.id;
  }, [props.id, props.active]);

  const getWhatToRender = () => {
    if (!active) {
      return null;
    } else if (!props.children && props.render) {
      return props.render(handleToggle);
    } else {
      return props.children;
    }
  };

  const getAccordionIcon = useCallback(
    (type: 'active' | 'passive', defaultIcon: IconDefinition) => {
      if (props.isWidget && isSingleWidgetView) return null;
      return props.accordionIcons ? <Icon name={props.accordionIcons[type]} /> : <FontAwesomeIcon icon={defaultIcon} />;
    },
    [props.accordionIcons, props.isWidget, isSingleWidgetView]
  );

  const id = props.id;
  const activeIcon = getAccordionIcon('active', faChevronDown);
  const passiveIcon = getAccordionIcon('passive', faChevronUp);
  const RenderableContent = getWhatToRender();

  return (
    <>
      <div id={id} className="AccordionHeader" onClick={handleToggle}>
        {!props.customIconClassname && props.icon && (
          <div className="icon-wrapper">
            <Icon name={props.icon} />
          </div>
        )}

        <div className="content-wrapper">
          {props.customIconClassname && (
            <div
              style={{ display: 'inline-block', marginRight: '10px', verticalAlign: 'middle' }}
              className={props.customIconClassname}
            />
          )}
          {props.title}
        </div>

        {props.label !== undefined && <Label content={props.label} />}

        <div className="icon-wrapper chevron">{active ? activeIcon : passiveIcon}</div>
      </div>

      <div
        id={id ? `${id}-content` : undefined}
        className="accordionContent"
        style={{ display: active ? 'block' : 'none' }}
      >
        {RenderableContent}
      </div>

      {props.divider && <div className="AccordionDivider" />}
    </>
  );
};

AccordionHeader.defaultProps = {
  active: false,
  divider: true
};

export default AccordionHeader;
