import { Direction, SortBy } from '@eeedo/types';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Icon } from 'semantic-ui-react';

import './Sorting.css';

import type { CSSProperties } from 'react';

const styles: {
  direction: CSSProperties;
  wrapper: CSSProperties;
  dropdown: Record<string, CSSProperties>;
} = {
  direction: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '5px',
    cursor: 'pointer'
  },
  wrapper: { display: 'flex', marginTop: '5px', marginBottom: '5px' },
  dropdown: {
    trigger: { whiteSpace: 'nowrap', borderRadius: '10px', display: 'flex', flexDirection: 'row', alignItems: 'top' },
    triggerIcon: { marginLeft: '10px' }
  }
};

interface Props {
  sorting: SortBy;
  direction: Direction;
  onChange: (sorting: SortBy, direction: Direction) => void;
}

const Sorting = ({ sorting, direction, onChange }: Props) => {
  const { t } = useTranslation();

  const options: { label: string; value: SortBy }[] = [
    { label: t('SORTING_OPTION_DUE_DATE'), value: SortBy.dueDate },
    { label: t('SORTING_OPTION_CREATED'), value: SortBy.created },
    { label: t('SORTING_OPTION_TOUCHED'), value: SortBy.touched }
  ];
  const activeOption = options.find((o) => o.value === sorting);
  const { value, label } = activeOption || {};

  return (
    <div style={styles.wrapper} id="sorting">
      <div
        id="sorting-direction"
        style={styles.direction}
        onClick={() => {
          onChange(sorting, direction === Direction.ASC ? Direction.DESC : Direction.ASC);
        }}
      >
        <Icon name={direction === Direction.ASC ? 'arrow up' : 'arrow down'} />
      </div>

      <Dropdown
        trigger={
          <Button style={styles.dropdown.trigger} basic>
            <div>{label}</div>
            {/* manually set icon so we can hide it more easily on small screen */}
            <FontAwesomeIcon
              style={styles.dropdown.triggerIcon}
              className="filters-sort-dropdown-icon"
              icon={faChevronDown}
            />
          </Button>
        }
        icon={false}
      >
        <Dropdown.Menu>
          {options.map((option) => (
            <Dropdown.Item
              selected={option.value === value}
              onClick={(_, data) => {
                onChange(data.value as SortBy, direction);
              }}
              value={option.value}
            >
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Sorting;
