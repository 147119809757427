import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';
import iziToast from 'izitoast';

import type { RoleWithPermission } from '@eeedo/types';

import { startAjaxCall } from './ajaxStatusActions';
import { FETCH_ROLES_SUCCESS, UPDATE_ROLE_SUCCESS } from './index';
import { initialRequestFailure, initialRequestSuccess } from './initActions';
import RolesApi from 'src/api/RolesApi';

export const loadRolesSuccess = createAction<RoleWithPermission[]>(FETCH_ROLES_SUCCESS);
export const updateRoleSuccess = createAction<RoleWithPermission>(UPDATE_ROLE_SUCCESS);

export const fetchRoles = createAsyncThunk<void, undefined, { rejectValue: Error }>(
  'fetchRoles',
  async (_, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'FETCH_ROLES' }));

    try {
      const roles = await RolesApi.getRoles();
      dispatch(loadRolesSuccess(roles));
      dispatch(initialRequestSuccess('roles'));
    } catch (error) {
      console.error('Failed to load roles', error);
      dispatch(initialRequestFailure('roles', error));
    }
  }
);

export const addPermissionToRole = createAsyncThunk<
  void,
  { roleId: number; permission: string },
  { rejectValue: Error }
>('addPermissionToRole', async ({ roleId, permission }, { dispatch }) => {
  dispatch(startAjaxCall({ name: 'ADD_PERMISSION_TO_ROLE' }));

  try {
    await RolesApi.addPermissionToRole(roleId, permission);
    iziToast.success({
      title: t('OK'),
      icon: 'icon check',
      message: t('management.roles_management.toasts.add_success'),
      timeout: 2500
    });
  } catch (error) {
    console.error('Failed to add permission to role', error);
    iziToast.error({
      title: `${t('ERROR')}!`,
      icon: 'icon delete',
      message: t('management.roles_management.toasts.add_failed'),
      timeout: 5000
    });
  }
});

export const deletePermissionFromRole = createAsyncThunk<
  void,
  { roleId: number; permission: string },
  { rejectValue: Error }
>('deletePermissionFromRole', async ({ roleId, permission }, { dispatch }) => {
  dispatch(startAjaxCall({ name: 'DELETE_PERMISSION_FROM_ROLE' }));

  try {
    await RolesApi.deletePermissionFromRole(roleId, permission);
    iziToast.success({
      title: t('OK'),
      icon: 'icon check',
      message: t('management.roles_management.toasts.delete_success'),
      timeout: 2500
    });
  } catch (error) {
    console.error('Failed to delete permission from role', error);
    iziToast.error({
      title: `${t('ERROR')}!`,
      icon: 'icon delete',
      message: t('management.roles_management.toasts.delete_failed'),
      timeout: 5000
    });
  }
});
