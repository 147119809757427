import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, Icon } from 'semantic-ui-react';

import type { PersonalData, TicketType } from '@eeedo/types';
import type { FC } from 'react';

import { addRelatedTicketToTicket, createNewSubTicket } from 'src/actions/ticketsActions';
import { defaultInfoPage } from 'src/api/InfoPage';
import { defaultTask } from 'src/api/Task';
import AddRelatedTicketById from 'src/Components/Case/Widget/TicketRelationsWidget/AddRelatedTicketById';
import Button from 'src/Components/generic/Button/Button';
import Section from 'src/Components/generic/Section/Section';

import type { ContentTypes } from 'src/types/ContentTypes';
import type { RelationOptions } from 'src/types/LinkedTickets';
import type { Ticket } from 'src/types/Ticket';

interface Props {
  attachPlaceholderText: string;
  createText: string;
  relationOptions: RelationOptions;
  task: Ticket;
  ticketTypes: TicketType[];
  type: ContentTypes;
  userData: PersonalData;
}

const TicketRelationsForm: FC<Props> = ({
  attachPlaceholderText,
  createText,
  relationOptions,
  task,
  ticketTypes,
  type,
  userData
}) => {
  const [relationType, setRelationType] = useState('parentToChild');
  const dispatch = useDispatch();

  const createNewSubContent = async (
    type: ContentTypes,
    defaultTaskType: string | number,
    parentId: string,
    ticketRelationType: string
  ) => {
    switch (type) {
      case 'task': {
        const newTask = defaultTask(defaultTaskType);
        dispatch(createNewSubTicket('task', newTask, true, parentId.substring(3), ticketRelationType));
        break;
      }
      case 'infopage': {
        if (typeof defaultTaskType === 'number') {
          defaultTaskType = defaultTaskType.toString();
        }

        const newPage = defaultInfoPage(defaultTaskType);
        dispatch(createNewSubTicket('infopage', newPage, true, parentId.substring(3), ticketRelationType));
        break;
      }
    }
  };

  return (
    <>
      <Section direction="column" gap={8} margin="0 0 10px 0">
        <Dropdown
          clearable={false}
          data-test-id="ticketRelationsDropdown"
          disabled={!userData.permissions.includes('updateContent')}
          fluid={true}
          multiple={false}
          noResultsMessage={''}
          options={relationOptions}
          placeholder={''}
          scrolling={true}
          search={false}
          selectOnBlur={false}
          selection={true}
          size="small"
          value={relationType || relationOptions[0].value}
          onChange={(e: any, data: any) => {
            setRelationType(data.value);
          }}
        />

        <Button
          disabled={!userData.permissions.includes('updateContent')}
          iconLeft={<Icon name="plus" />}
          fullWidth
          type="primary"
          center
          onClick={() => {
            createNewSubContent(
              type,
              ticketTypes.find((ticketType) => ticketType.id === userData.userPreferences.defaultTicketType)!.name,
              task.id,
              relationType
            );
          }}
        >
          {createText}
        </Button>

        <AddRelatedTicketById
          placeholder={attachPlaceholderText}
          addRelatedTicketToTicket={(targetId) => {
            dispatch(addRelatedTicketToTicket(task.id, targetId, relationType));
          }}
        />
      </Section>
    </>
  );
};

export default TicketRelationsForm;
