import React from 'react';

import type { Tag } from '@eeedo/types';
import type { ComponentProps, CSSProperties } from 'react';
import type { SemanticShorthandItem } from 'semantic-ui-react/dist/commonjs/generic';

import Input from '../generic/Input/Input';

import type { OnBlurEvent, OnKeyDownEvent } from './SearchEvents';
import type { FormattedSearch } from 'src/types/Search';

type InputProps = ComponentProps<typeof Input>;

interface TextSearchProps {
  // Id to use for the parameter.
  // Search will be appended to this for the input's id
  id: keyof FormattedSearch['basic'];
  name: string;
  autocomplete?: boolean;

  onSave: (
    param: keyof FormattedSearch['basic'],
    value: string | boolean | Tag[] | null | undefined,
    object: any,
    partial: boolean,
    name: string,
    text: string,
    datagroup?: string,
    entityType?: string,
    id?: any,
    entityTypes?: string[]
  ) => void;

  onSet: (value: string) => void;
}

function onBlur(e: OnBlurEvent, props: TextSearchProps) {
  const value = e.target.value;
  props.onSave(`${props.id}` as keyof FormattedSearch['basic'], value, undefined, false, props.name, value, 'basic');
  props.onSet(value);
}

function onKeyDown(e: OnKeyDownEvent, props: TextSearchProps) {
  if (e.key === 'Enter') {
    const value = e.target.value;
    props.onSave(`${props.id}` as keyof FormattedSearch['basic'], value, undefined, false, props.name, value, 'basic');
    props.onSet(value);
  }
}

export default function TextSearch(props: TextSearchProps) {
  const id = `${props.id}Search`;
  const icon: SemanticShorthandItem<InputProps> = 'search';
  const style: CSSProperties = { marginBottom: '5px' };

  const inputProps: InputProps = {
    id: id,
    onBlur: (e: OnBlurEvent) => onBlur(e, props),
    onKeyDown: (e: OnKeyDownEvent) => onKeyDown(e, props),
    placeholder: props.name,
    icon,
    fluid: true,
    style: style
  };

  // React won't recognize the input as empty if you make this
  // a single element with a single ternary inside.
  // So we will have to settle for this.
  if (props.autocomplete) {
    return (
      <Input {...inputProps}>
        <input autoComplete={id} />
      </Input>
    );
  } else {
    return <Input {...inputProps} />;
  }
}
