import React from 'react';
import { Label } from 'semantic-ui-react';

import type { Tag as TagData } from '@eeedo/types';
import type { FC } from 'react';

import * as styles from './ArticleItemTags.style';
import Popup from 'src/Components/generic/Popup/Popup';
import Section from 'src/Components/generic/Section/Section';
import Tag from 'src/Components/generic/Tag/Tag';

interface Props {
  keyPrefix: string;
  tags: TagData[];
  children?: React.ReactNode;
}

const TagsList: FC<Props> = ({ keyPrefix, tags, children }) => (
  <Section gap={8} wrap="wrap">
    {tags.map((tag, index) => (
      <div key={`${keyPrefix}-${tag.name}-${index}`} style={styles.paddedContainer}>
        <Tag tag={tag} />
      </div>
    ))}
    {children}
  </Section>
);
const ArticleItemTags: FC<Props> = ({ keyPrefix, tags }) => (
  <TagsList keyPrefix={keyPrefix} tags={tags.slice(0, 3)}>
    {tags.length > 3 && (
      <Popup
        on={'hover'}
        trigger={
          <span style={styles.paddedContainer}>
            <Label>...</Label>
          </span>
        }
      >
        <TagsList keyPrefix={`${keyPrefix}-popup`} tags={tags} />
      </Popup>
    )}
  </TagsList>
);

export default React.memo(ArticleItemTags);
