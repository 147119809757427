import React from 'react';
import { useTranslation } from 'react-i18next';

import GenerateTags from './GenerateTags';
import GenerateTranscriptionsSummary from './GenerateTranscriptionsSummary';
import RelevantInformation from './RelevantInformation';
import AccordionHeader from 'src/Components/Case/AccordionHeader';
import Section from 'src/Components/generic/Section/Section';

import type { Ticket } from 'src/types/Ticket';

interface ChatGPTWidgetProps {
  task: Ticket;
}

export default ({ task }: ChatGPTWidgetProps) => {
  const { t } = useTranslation();

  return (
    <AccordionHeader
      key="eIdentification-widget"
      as="h4"
      active={true}
      title={t('widgets.chatGPT.title')}
      icon="microsoft"
      isWidget={true}
    >
      <Section direction="column" gap={8}>
        <RelevantInformation task={task} />
        <GenerateTags task={task} />
        <GenerateTranscriptionsSummary id={parseInt(task.id.substring(3), 10)} />
      </Section>
    </AccordionHeader>
  );
};
