import { createAsyncThunk } from '@reduxjs/toolkit';

import type { LoginStrategy, NotificationRule, PersonalData, UserWithProfile } from '@eeedo/types';
import type { AxiosError } from 'axios';
import type { Action, ActionCreator, Dispatch } from 'redux';
import type { ThunkAction } from 'redux-thunk';

import { startAjaxCall } from './ajaxStatusActions';
import {
  ADD_FORBIDDEN_LOGIN_STRATEGY,
  ADD_USER_CHANNEL,
  ADD_USER_TICKET_TYPE,
  FETCH_PERSONAL_DATA_FAILURE,
  FETCH_PERSONAL_DATA_SUCCESS,
  FETCH_USERS_FAILURE,
  PATCH_USER_NOTIFICATIONS_SUCCESS,
  REMOVE_FORBIDDEN_LOGIN_STRATEGY,
  REMOVE_USER_CHANNEL,
  REMOVE_USER_TICKET_TYPE
} from './index';
import { initialRequestFailure, initialRequestSuccess } from './initActions';
import { setChatAnchor } from './userActionsRTK';
import NotificationApi from 'src/api/NotificationApi';
import UsersApi from 'src/api/UsersApi';
import { fetchUsersSuccess, fetchUserSuccess } from 'src/reducers/usersListReducer';
import { userToNumericalId } from 'src/Utilities/users';

import type { ChangePasswordData } from 'src/types/ApiRequests';
import type { State } from 'src/types/initialState';

export const loadUsersFailure = (error: Error) => {
  return { type: FETCH_USERS_FAILURE, payload: { type: 'users', error: error } };
};

export const patchUserNotificationSuccess = (channels: number[]) => {
  return { type: PATCH_USER_NOTIFICATIONS_SUCCESS, channels };
};

export const loadPersonalDataSuccess = (data: PersonalData) => {
  return { type: FETCH_PERSONAL_DATA_SUCCESS, payload: data };
};

export const loadPersonalDataFailure = (error: Error) => {
  return { type: FETCH_PERSONAL_DATA_FAILURE, payload: { type: 'personalData', error: error } };
};

export const createUser: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (data: UserWithProfile) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'CREATE_USER' }));
    return UsersApi.createUser(data).catch((error: AxiosError) => {
      throw error;
    });
  };
};

export const changePassword: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (
  data: ChangePasswordData
) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'UPDATE_PASSWORD' }));
    return UsersApi.changePassword(data).catch((error: AxiosError) => {
      throw error;
    });
  };
};

export const fetchUsers = createAsyncThunk<UserWithProfile[] | undefined, void, { rejectValue: Error }>(
  'fetchUsers',
  async (_, { dispatch, getState }) => {
    dispatch(startAjaxCall({ name: 'FETCH_USERS' }));

    try {
      const users = await UsersApi.getUsers();
      const { userData } = getState() as State;
      const user = users.find(({ UID }) => UID === userData.UID);

      dispatch(fetchUsersSuccess({ users }));
      dispatch(initialRequestSuccess('users'));

      if (user) {
        await dispatch(fetchPersonalData());
      }

      return users;
    } catch (error) {
      console.error('Failed to load users', error);
      dispatch(loadUsersFailure(error as Error));
      dispatch(initialRequestFailure('users', error));

      return undefined;
    }
  }
);

export const fetchUser = (id: number | string) => {
  return (dispatch: Dispatch) => {
    id = userToNumericalId(id);
    dispatch(startAjaxCall({ name: 'FETCH_USER' }));
    return UsersApi.getUser(id).then((user) => {
      dispatch(fetchUserSuccess({ user }));
    });
  };
};

export const fetchPersonalData = createAsyncThunk<PersonalData | undefined, void, { rejectValue: Error }>(
  'fetchPersonalData',
  async (_, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'FETCH_PERSONAL_DATA' }));

    try {
      const personalData = await UsersApi.getPersonalData();
      dispatch(loadPersonalDataSuccess(personalData));
      dispatch(initialRequestSuccess('personalData'));

      return personalData;
    } catch (error) {
      console.error('Failed to load personalData', error);
      dispatch(loadPersonalDataFailure(error as Error));
      dispatch(initialRequestFailure('personalData', error));

      return undefined;
    }
  }
);

export const patchUserProfile = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'PATCH_USER_PROFILE' }));
    return UsersApi.patchUserProfile(data);
  };
};

export const addUserTicketType = (user: number, ticketType: number) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: ADD_USER_TICKET_TYPE }));
    return UsersApi.addTicketType(user, ticketType);
  };
};

export const removeUserTicketType = (user: number, ticketType: number) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: REMOVE_USER_TICKET_TYPE }));
    return UsersApi.removeTicketType(user, ticketType);
  };
};

export const addUserChannel = (user: number, channel: number) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: ADD_USER_CHANNEL }));
    return UsersApi.addChannel(user, channel);
  };
};

export const removeUserChannel = (user: number, channel: number) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: REMOVE_USER_CHANNEL }));
    return UsersApi.removeChannel(user, channel);
  };
};

export const addForbiddenLoginStrategy = (user: number, strategy: LoginStrategy) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: ADD_FORBIDDEN_LOGIN_STRATEGY }));
    return UsersApi.addForbiddenLoginStrategy(user, strategy);
  };
};

export const removeForbiddenLoginStrategy = (user: number, strategy: LoginStrategy) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: REMOVE_FORBIDDEN_LOGIN_STRATEGY }));
    return UsersApi.removeForbiddenLoginStrategy(user, strategy);
  };
};

export const createNotifications: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (
  notificationPayload: Partial<NotificationRule>
) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'POST_NOTIFICATION' }));
    return NotificationApi.createNotification(notificationPayload)
      .then((createdNotification) => {
        return createdNotification;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };
};

export const setUserChatAnchorStatus: ActionCreator<ThunkAction<any, any, any, Action<any>>> = (
  chatAnchored: boolean
) => {
  return (dispatch) => {
    return UsersApi.setTicketAnchor(chatAnchored)
      .then((chatAnchorResponse: { chatAnchored: boolean }) => {
        dispatch(setChatAnchor({ chatAnchored: chatAnchorResponse.chatAnchored }));
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };
};

export const patchNotifications: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (
  notificationId: number,
  notificationPayload: Partial<NotificationRule>
) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'PATCH_NOTIFICATION' }));
    return NotificationApi.updateNotification(notificationId, notificationPayload)
      .then((updatedNotification) => {
        return updatedNotification;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };
};

export const deleteNotification: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (
  notificationId: number
) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'DELETE_NOTIFICATION' }));
    return NotificationApi.deleteNotification(notificationId)
      .then(({ msg }) => {
        return msg;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };
};
