import { faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { generateTranscriptionsSummary } from 'src/actions/openAIActions';
import Button from 'src/Components/generic/Button/Button';
import { useAppThunkDispatch } from 'src/store';

interface GenerateTagsProps {
  id: number;
}

export default (props: GenerateTagsProps) => {
  const { t } = useTranslation();
  const dispatchThunk = useAppThunkDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleGenerateSummary = async () => {
    if (!isLoading) {
      setIsLoading(true);
      await dispatchThunk(
        generateTranscriptionsSummary({
          contentId: props.id
        })
      );
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Button
        fullWidth
        disabled={isLoading}
        onClick={() => handleGenerateSummary()}
        type="primary"
        iconLeft={<FontAwesomeIcon icon={faPhone} />}
        isLoading={isLoading}
      >
        {t('widgets.chatGPT.buttons.generate_transcriptions_summary')}
      </Button>
    </div>
  );
};
