import React from 'react';
import { useSelector } from 'react-redux';
import { Accordion, Icon } from 'semantic-ui-react';

import type { FC } from 'react';

import TicketList from './TicketList';

import type { ContentTypes } from 'src/types/ContentTypes';
import type { State } from 'src/types/initialState';
import type { InfopageStatusTypes, StatusTypes, Ticket } from 'src/types/Ticket';

import 'react-virtualized/styles.css';

import Label from '../generic/Label/Label';

interface TicketListProps {
  type: ContentTypes;
  selectedTicketId: string | null;
  active: boolean;
  index: number;
  tickets: Ticket[];
  title: string;
  status: StatusTypes | InfopageStatusTypes;
  shouldOpenTicket?: boolean;

  onClick(index: number): void;
  startWorkingOn?: (currentUserId: string, taskId: string) => void;
}

const TicketListAccordion: FC<TicketListProps> = ({
  type,
  index,
  status,
  active,
  tickets,
  shouldOpenTicket,
  selectedTicketId,
  title: originalTitle,
  onClick,
  startWorkingOn
}) => {
  const leftTabOpen = useSelector((state: State) => state.mobileReducer.leftTabOpen);
  const mobileMode = useSelector((state: State) => state.mobileReducer.mobileMode);

  // Doing accordion switch without debounce
  const onClickChangeAccordion = () => {
    if (index !== null) {
      onClick(index);
    }
  };

  const icon = <Icon name={active ? 'chevron up' : 'chevron right'} />;
  const title = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginBottom: '10px'
      }}
    >
      <span style={{ fontSize: '1.2em', paddingTop: '2px' }}>{originalTitle}</span>
      <span style={{ marginLeft: '5px' }}>
        <Label content={tickets.length} basic />
      </span>
    </div>
  );

  return (
    <React.Fragment>
      <Accordion.Title className="ticketlist" active={active} onClick={onClickChangeAccordion} id={status}>
        {title} {icon}
      </Accordion.Title>
      <Accordion.Content id={status + '_content'} active={active} style={{ flex: '1 1 auto' }}>
        {/* triggers list re-render */}
        {(!mobileMode || leftTabOpen) && (
          <TicketList
            type={type}
            active={active}
            tickets={tickets}
            shouldOpenTicket={shouldOpenTicket}
            selectedTicketId={selectedTicketId}
            startWorkingOn={startWorkingOn}
          />
        )}
      </Accordion.Content>
    </React.Fragment>
  );
};

export default React.memo(TicketListAccordion);
