import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';

import ErrorBoundary from '../../ErrorBoundary';
import DateSelector from '../Case/DateSelector';
import Section from '../generic/Section/Section';
import { camelToSnakeCase } from 'src/Utilities/casing';

import type { SearchCriterion } from 'src/types/Search';

export const DatesValues = [
  'createdAfter',
  'createdBefore',
  'touchedAfter',
  'touchedBefore',
  'duedateAfter',
  'duedateBefore'
] as const;
export type Dates = (typeof DatesValues)[number];

export interface SearchDateRangesProps {
  noDefaultDates?: boolean;
  onChange: (param: Dates, value: string | undefined) => void;
  values: SearchCriterion[];
}

const valuesToDates = (values: SearchCriterion[]) => {
  return values.reduce<{ [key in Dates]?: string }>(
    (prev, curr) => ({
      ...prev,
      ...(DatesValues.includes(curr.param as Dates) && { [curr.param]: curr.value })
    }),
    {}
  );
};

export const SearchDateRanges = (props: SearchDateRangesProps) => {
  const dates = valuesToDates(props.values);
  const { t } = useTranslation();

  return (
    <ErrorBoundary>
      <Section direction="column" gap={8}>
        {DatesValues.map((date, i) => (
          <div key={`${date}-${i}`}>
            <Section direction="column" padding=" 0 1px">
              <Header as="h5">{t(`search.date.${camelToSnakeCase(date)}`)}</Header>
              <DateSelector
                value={dates[date]}
                onChange={(timestamp) => {
                  props.onChange(date, timestamp);
                }}
              />
            </Section>
          </div>
        ))}
      </Section>
    </ErrorBoundary>
  );
};
