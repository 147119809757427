import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from 'semantic-ui-react';

import type { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';

import useCommands from './useCommands';
import { activateTab } from 'src/actions/tabActionsRTK';
import { activateTicket } from 'src/actions/ticketsActions';
import Dropdown from 'src/Components/generic/Dropdown/Dropdown';
import { StaticTabs } from 'src/types/TicketList';
import { parseTicketNumber } from 'src/Utilities/parseUtils';

import type { State } from 'src/types/initialState';

import './CommandPalette.css';

const ShortCut = (props: { keypath: string }) => {
  return (
    <span style={{ float: 'right' }}>
      {props.keypath.split(',').map((k) => (
        <Label className="command-palette-list-label-border" content={k} key={k} />
      ))}
    </span>
  );
};

export const CommandPalette = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const tickets = useSelector((state: State) => state.ticketListTabs[StaticTabs.MAIN_VIEW].tickets);
  const isCommand = searchQuery.includes('>');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [commandPrompts] = useCommands();

  const matchedTicketsPrompts = useMemo(
    () =>
      tickets
        .map(({ id, title }) => ({
          value: id,
          text: title
        }))
        .reverse(),
    [tickets]
  );

  const renderItemLabel = (text: string) => ({
    value: text,
    content: <div>{text}</div>,
    disabled: true,
    className: 'label'
  });

  const dropdownOptions: DropdownItemProps[] = isCommand
    ? [renderItemLabel('Commands'), ...commandPrompts]
    : [renderItemLabel('Tickets'), ...matchedTicketsPrompts, renderItemLabel('Commands'), ...commandPrompts];

  const customSearch = (options: DropdownItemProps[], value: string): DropdownItemProps[] => {
    const searchString = value.toLowerCase().replaceAll('>', '');
    const searchTicketId = parseTicketNumber(value);

    return options.filter(
      (option) =>
        (searchTicketId && `${option.value}`.includes(searchTicketId.toString())) ||
        `${option.value} ${option.text}`.toLowerCase().includes(searchString) ||
        option.className === 'label'
    );
  };

  const onResultSelect = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
    const command = commandPrompts.find((prompt) => prompt.value === data.value);

    if (command) {
      command?.action?.();
    } else if (data.value) {
      dispatch(activateTicket(data.value));
      dispatch(activateTab(data.value));
    }

    setSearchQuery('');
  };

  return (
    <>
      <Dropdown
        className="command_palette"
        fluid
        icon={<FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />}
        iconPosition="left"
        noResultsMessage={t('PLACEHOLDER_SEARCH_NO_RESULTS')}
        onChange={onResultSelect}
        onSearchChange={(_, data) => setSearchQuery(data.searchQuery)}
        placeholder={t('SEARCH')}
        search={customSearch}
        searchInput={{ size: 'huge' }}
        searchQuery={searchQuery}
        selectOnBlur={false}
        selectOnNavigation={false}
        lazyLoad
        selection
        size="large"
        value=""
        options={dropdownOptions.map((option: DropdownItemProps) => ({
          ...option,
          content: option.content ?? (
            <div className="option_content">
              <div>
                {!searchQuery.includes('>') && !option.action ? (
                  <Label content={option.value} />
                ) : (
                  <Label content=">" />
                )}
                <span>{option.text}</span>
              </div>
              <div>{option.shortcut && <ShortCut keypath={option.shortcut} />}</div>
            </div>
          )
        }))}
      />
    </>
  );
};
