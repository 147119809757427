import type { RoleWithPermission } from '@eeedo/types';

import { apiClient } from 'src/Utilities/httpClients';

export default class RolesApi {
  static getRoles = async (): Promise<RoleWithPermission[]> => {
    return apiClient.get('/roles').then((response) => response.data);
  };

  static addPermissionToRole = async (roleId: number, permission: string) => {
    return apiClient.post(`/roles/${roleId}/permissions/${permission}`).then((response) => response.data);
  };

  static deletePermissionFromRole = async (roleId: number, permission: string) => {
    return apiClient.delete(`/roles/${roleId}/permissions/${permission}`).then((response) => response.data);
  };

  static getPermissions = async (): Promise<string[]> => {
    return apiClient.get('/permissions').then((response) => response.data);
  };
}
