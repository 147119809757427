import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Label as SLabel } from 'semantic-ui-react';

import type { CSSProperties, FC } from 'react';
import type { StrictLabelProps } from 'semantic-ui-react';

import './Label.css';

interface Props extends StrictLabelProps {
  disabled?: boolean;
  style?: CSSProperties;
  rounded?: boolean;
}

const Label: FC<Props> = ({ color = 'grey', disabled, rounded, ...props }) => {
  return (
    <SLabel
      {...props}
      as={!disabled && 'a'}
      style={props.style}
      className={classNames(
        'eeedoLabel',
        color && `color__${color}`,
        disabled && 'disabled',
        rounded && 'rounded',
        props.className
      )}
      removeIcon={<FontAwesomeIcon icon={faXmark} className="removeIcon" />}
    />
  );
};

export default Label;
