import React from 'react';

import type { FC } from 'react';

import { WidgetViewProvider } from '../Case/Widget/WidgetViewContext';
import DesktopLayout from './Desktop/DesktopLayout';
import MobileLayout from './Mobile/MobileLayout';

interface Props {
  mobileMode: boolean;
  children?: React.ReactNode;
}

const Layout: FC<Props> = ({ mobileMode, children }) => {
  if (mobileMode) {
    return <MobileLayout children={children} />;
  } else {
    return (
      <WidgetViewProvider>
        <DesktopLayout />
      </WidgetViewProvider>
    );
  }
};

export default Layout;
