import { faRemove } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';

import Button from '../generic/Button/Button';

type ModalProps = { title: string; onClickClose: () => void; children: React.ReactNode };

export const ModalWindow: React.FC<ModalProps> = ({ title, children, onClickClose }) => {
  const { t } = useTranslation();

  return (
    <Modal closeOnDocumentClick closeOnDimmerClick closeOnEscape onClose={onClickClose} open>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content scrolling>{children}</Modal.Content>
      <Modal.Actions style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="primary"
          content={t('GENERAL_CLOSE')}
          onClick={onClickClose}
          iconLeft={<FontAwesomeIcon icon={faRemove} />}
        />
      </Modal.Actions>
    </Modal>
  );
};
